import "./PlotImage.css";

import { PlotModal } from "../PlotModal/PlotModal";
import PropTypes from "prop-types";
import { jsonContentType } from "../../services/backendRequests";
import noImage from "../../static/img/microplot_placeholder.png";
import useSWRImmutable from "swr/immutable";
import { useSelector } from "react-redux";
import { useState } from "react";

const imageFetcher = (user) => async (url) => {
  let response;
  response = await fetch(url, {
    method: "GET",
    headers: jsonContentType(user.identity),
  });

  if (response.status === 404) return { url: noImage };
  if (response.ok) return await response.json();
};

export const PlotImage = ({ feature, date, active, bottomRightContent }) => {
  const user = useSelector((state) => state.user);
  const trial = useSelector((state) => state.resultMap.trial);

  const { data, isLoading, error } = useSWRImmutable(
    `/api/client/trials/${trial.id}/features_sample/${feature.id}?date=${date}`,
    imageFetcher(user)
  );

  const src = error ? "An error occured" : data?.url;

  const [plotModalIsOpen, setPlotModalIsOpen] = useState(false);

  const handleClosePlotModal = () => {
    setPlotModalIsOpen(false);
  };

  return (
    <div className={`img-wrapper ${active ? "current" : ""}`}>
      <PlotModal
        src={src}
        isOpen={plotModalIsOpen}
        toggle={handleClosePlotModal}
      />
      {isLoading ? (
        <div className="img-skeleton" />
      ) : (
        <img
          className="clickable"
          src={src}
          alt=""
          style={{ minHeight: 50 }}
          onClick={() => setPlotModalIsOpen(true)}
        />
      )}

      <div className="d-flex align-items-center justify-content-between w-100">
        <span className="informative p-1">
          <i className="fa-solid fa-image" /> {date}
        </span>
        {bottomRightContent}
      </div>
    </div>
  );
};

PlotImage.propTypes = {
  feature: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  active: PropTypes.bool,
  bottomRightContent: PropTypes.node,
};
