import "./varietyThumbnail.css";

import { useEffect, useMemo, useState } from "react";

import { Badge } from "reactstrap";
import { BlackListWhiteList } from "../advancedFiltering/blackListWhiteList/BlackListWhiteList";
import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import { PlotModal } from "../../../../components/PlotModal/PlotModal";
import PropTypes from "prop-types";
import { PropertiesMasonry } from "../../../../components/PropertiesMasonry/PropertiesMasonry";
import { selectNumericalPropertiesMetricsAndAggregatedFeatures } from "../../../../selectors/resultMap";
import { useGetGroupColor } from "../../../../hooks/useGetGroupColor";
import { useSelector } from "react-redux";

export const VarietyThumbnail = ({
  index,
  propertyFilter,
  getImage,
  highlightedProperty,
  setHighlightedProperty,
  horizontal,
}) => {
  const visualizedVarieties = useSelector(
    ({ resultMap }) => resultMap.visualizedVarieties
  );
  const variety = visualizedVarieties[index];

  const getColor = useGetGroupColor();
  const color = getColor(variety);

  const [aggregatedFeatures] = useSelector(
    selectNumericalPropertiesMetricsAndAggregatedFeatures
  );

  const features = useMemo(
    () =>
      aggregatedFeatures.find(({ group }) => group === variety)?.features || [],
    [aggregatedFeatures, variety]
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const handlePrev = (event) => {
    event.stopPropagation();
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    else setCurrentIndex(features.length - 1);
  };
  const handleNext = (event) => {
    event.stopPropagation();
    setCurrentIndex(currentIndex + 1);
  };
  const selectedFeature = useMemo(
    () => features[currentIndex % features.length],
    [currentIndex, features]
  );

  const [image, setImage] = useState(null);
  const [imageIsLoading, setImageIsLoading] = useState(false);

  useEffect(() => {
    // TODO: prevent refetch
    const fetchImage = async (feature) => {
      setImageIsLoading(true);
      const image = await getImage(feature, "base");
      setImageIsLoading(false);
      setImage(image);
    };

    if (selectedFeature) fetchImage(selectedFeature);
  }, [getImage, selectedFeature]);

  const [plotModalIsOpen, setPlotModalOpen] = useState(false);

  const handleClosePlotModal = () => {
    setPlotModalOpen(false);
  };

  return (
    <div
      className={`powerdash-component p-0 variety-thumbnail ${
        variety ? "" : "translucid"
      }
      `}
      style={{ background: color }}
    >
      <PlotModal
        src={image?.url}
        isOpen={plotModalIsOpen}
        toggle={handleClosePlotModal}
      />
      {variety && (
        <>
          <div className="variety-thumbnail-title gap-2">
            {variety}{" "}
            {features.length > 0 && <BlackListWhiteList group={variety} />}
          </div>
          {features.length > 0 ? (
            <div
              className={`variety-thumbnail-content ${
                horizontal ? "horizontal" : ""
              }`}
            >
              <div
                className={`variety-thumbnail-carousel clickable${
                  imageIsLoading ? "loading" : ""
                }`}
                style={{
                  backgroundImage: `url(${image?.url})`,
                  backgroundColor: image?.blank ? "#C3C3C3" : "black",
                }}
                onClick={() => setPlotModalOpen(true)}
              >
                {!horizontal && (
                  <div className="carousel-img-popover">
                    <div className="carousel-img-popover-header informative">
                      Plot {selectedFeature?.displayId}
                    </div>
                    <img src={image?.url} alt="" />
                  </div>
                )}
                <div className="carousel-header">
                  <span className="informative">
                    Plot {selectedFeature?.displayId}
                  </span>
                  {image && !image?.blank && (
                    <a
                      href={image.url}
                      target="_blank"
                      rel="noreferrer"
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Download image"
                      data-tooltip-place="top"
                    >
                      <i className="fa-solid fa-lg fa-download" />
                    </a>
                  )}
                </div>
                {features.length > 1 && (
                  <>
                    <div className="carousel-controls">
                      <div className="carousel-control" onClick={handlePrev}>
                        <i className="fa fa-lg fa-chevron-left"></i>
                      </div>
                      <div className="carousel-control" onClick={handleNext}>
                        <i className="fa fa-lg fa-chevron-right"></i>
                      </div>
                    </div>
                    <Badge className="carousel-index-badge">
                      {(currentIndex % features.length) + 1} / {features.length}
                    </Badge>
                  </>
                )}
              </div>
              <PropertiesMasonry
                feature={selectedFeature}
                propertyFilter={propertyFilter}
                highlightedProperty={highlightedProperty}
                setHighlightedProperty={setHighlightedProperty}
              />
            </div>
          ) : (
            <ComponentPlaceholder
              icon={"fa fa-ban"}
              text="No plot for this variety in current data"
            />
          )}
        </>
      )}
    </div>
  );
};

VarietyThumbnail.propTypes = {
  index: PropTypes.number.isRequired,
  propertyFilter: PropTypes.string.isRequired,
  getImage: PropTypes.func.isRequired,
  setHighlightedProperty: PropTypes.func.isRequired,
  highlightedProperty: PropTypes.string,
  horizontal: PropTypes.bool,
};
