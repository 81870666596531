import { Badge } from "reactstrap";
import { ImageHistory } from "../../../../../components/PlotCard/ImageHistory";
import PropTypes from "prop-types";
import { addFocusedVarieties } from "../../../../../actions/resultMap";
import { useDispatch } from "react-redux";
import { useGetGroupColor } from "../../../../../hooks/useGetGroupColor";

export const PlotHistory = ({ plot, deselectPlot }) => {
  const getColor = useGetGroupColor();
  const dispatch = useDispatch();
  return (
    <div className="powerdash-component translucid">
      {plot && (
        <>
          <div className="d-flex justify-content-between align-items-center p-1">
            <span
              className="informative text-ellipsis flex-shrink-0"
              title={plot.displayId}
            >
              Plot {plot.displayId}
            </span>
            <Badge
              color="invalid"
              className="clickable text-ellipsis"
              style={{ background: getColor(plot.group), color: "black" }}
              onClick={() => dispatch(addFocusedVarieties([plot.group]))}
            >
              <i className="fa-solid fa-dna" /> {plot.group}
            </Badge>
            <i className="fa-solid fa-close clickable" onClick={deselectPlot} />
          </div>
          <ImageHistory feature={plot} />
        </>
      )}
    </div>
  );
};

PlotHistory.propTypes = {
  plot: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayId: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
  }),
  deselectPlot: PropTypes.func.isRequired,
};
