import { ANALYTICS_EVENTS, HIPHEN_GREEN, THEME } from "../../../../constants";
import { COMMON_PLOT_CONFIG, NULL_GROUP_LABEL } from "../../../constants";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Chart } from "./Chart";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { PropertyName } from "../../../../components/PropertyName/PropertyName";
import { RandomSelectButton } from "../randomSelect/RandomSelectButton";
import { addFocusedVarieties } from "../../../../actions/resultMap";
import { selectFilteredAggregatedFeatures } from "../../../../selectors/resultMap";
import { truncateNumber } from "../../../../services/utils";
import { useGetGroupColor } from "../../../../hooks/useGetGroupColor";
import { useGetPropertyCategory } from "../../../../hooks/useGetPropertyCategory";
import { useTracking } from "../../../../analytics";

const PLOT_STYLE = { width: "100%", height: "100%" };

export const GenotypeAnalyzer = ({ trait, exportName }) => {
  const [modalities, favoriteVarieties] = useSelector(({ resultMap }) => [
    resultMap.distinctModalities,
    resultMap.favoriteVarieties,
  ]);

  const aggregatedFeatures = useSelector(selectFilteredAggregatedFeatures);

  const getColor = useGetGroupColor();

  const [displayData, longestGenotypeCharLength] = useMemo(() => {
    const modPreparedData = {};
    // This is used to give an approximation on yAxis margin to avoid using automargin
    let longestGenotypeCharLength = 0;

    modalities.forEach((modality) => {
      modality = modality ?? "";
      modPreparedData[modality] = {
        name: modality,
        meta: modality === "" ? NULL_GROUP_LABEL : modality,
        x: [],
        y: [],
        error_x: {
          symmetric: false,
          arrayminus: [],
          array: [],
          width: 2,
          thickness: 1.5,
        },
        mode: "markers",
        hovertemplate:
          "<b>Genotype: %{y}</b><extra></extra><br>Mean: %{x:}<br>Variation: %{error_x.array}<br>Modality: %{meta}",
        marker: {
          opacity: 0.7,
          size: [],
          symbol: [],
          line: {
            color: [],
            width: 2,
          },
        },
        type: modalities.length > 1 ? "scatter" : "scattergl",
      };
    });

    aggregatedFeatures
      .toSorted((a, b) => {
        // Sort null values at bottom
        return (
          (a.properties[trait.technical_name] != null) -
            (b.properties[trait.technical_name] != null) ||
          a.properties[trait.technical_name]?.mean -
            b.properties[trait.technical_name]?.mean
        );
      })
      .forEach(({ group, modality, properties, genotype, isControl }) => {
        const traitProperties = properties[trait.technical_name];
        if (!traitProperties) return;

        modPreparedData[modality].x.push(truncateNumber(traitProperties.mean));
        modPreparedData[modality].y.push(genotype);
        modPreparedData[modality].error_x.arrayminus.push(
          truncateNumber(traitProperties.mean - traitProperties.min)
        );
        modPreparedData[modality].error_x.array.push(
          truncateNumber(traitProperties.max - traitProperties.mean)
        );
        modPreparedData[modality].marker.line.color.push(getColor(group));
        modPreparedData[modality].marker.symbol.push(
          isControl
            ? "star-diamond-dot"
            : favoriteVarieties.includes(group)
            ? "star"
            : "diamond"
        );
        modPreparedData[modality].marker.size.push(isControl ? 16 : 6);

        longestGenotypeCharLength = Math.max(
          longestGenotypeCharLength,
          genotype.length
        );
      });

    return [Object.values(modPreparedData), longestGenotypeCharLength];
  }, [
    aggregatedFeatures,
    favoriteVarieties,
    getColor,
    modalities,
    trait.technical_name,
  ]);

  const getPropertyCategory = useGetPropertyCategory();

  const layout = useMemo(
    () => ({
      autosize: true,
      uirevision: false,
      dragmode: "select",
      margin: { r: 15, l: 10 + longestGenotypeCharLength * 8.5, t: 0, b: 30 },
      plot_bgcolor: "transparent",
      paper_bgcolor: "white",
      yaxis: {
        gridcolor: THEME.indicators,
        type: "category",
      },
      xaxis: {
        gridwidth: 1,
        gridcolor: THEME.indicators,
      },
      font: { color: THEME.indicators, size: 15 },
      legend: {
        y: 0.9,
      },
      colorway:
        modalities.length > 1
          ? modalities.map((modality) =>
              modality ? THEME.modalityColorHash.hex(modality) : HIPHEN_GREEN
            )
          : [getPropertyCategory(trait.technical_name).color],
    }),
    [
      getPropertyCategory,
      longestGenotypeCharLength,
      modalities,
      trait.technical_name,
    ]
  );

  const config = useMemo(
    () => ({
      scrollZoom: true,
      toImageButtonOptions: {
        filename: exportName,
      },
      ...COMMON_PLOT_CONFIG,
      modeBarButtonsToRemove: [
        "pan",
        "select2d",
        "zoom2d",
        "lasso2d",
        "zoomIn2d",
        "zoomOut2d",
        "autoScale2d",
      ],
    }),
    [exportName]
  );

  const dispatch = useDispatch();

  const { trackEvent } = useTracking();

  const onSelected = useCallback(
    (event) => {
      if (!event) return;
      if (event.points.length === 0) return;
      trackEvent(ANALYTICS_EVENTS.SELECT_FROM_GENOTYPE_ANALYZER);
      const groups = event.points.map((point) => {
        return `${point.y} ${point.data.name}`.trim();
      });
      dispatch(addFocusedVarieties(groups));
    },
    [dispatch, trackEvent]
  );

  return (
    <Chart
      icon="fa-list-ol"
      title={
        trait.technical_name && (
          <span>
            Genotypes performance on{" "}
            <PropertyName
              showIcon
              showUnit
              technicalName={trait.technical_name}
            />
          </span>
        )
      }
      topRightContent={<RandomSelectButton key={aggregatedFeatures} />}
      missingData={aggregatedFeatures.length === 0 || !trait}
    >
      <Plot
        data={displayData}
        layout={layout}
        onSelected={onSelected}
        config={config}
        style={PLOT_STYLE}
      />
    </Chart>
  );
};

GenotypeAnalyzer.propTypes = {
  trait: PropTypes.object.isRequired,
  exportName: PropTypes.string.isRequired,
};
