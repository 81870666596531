import "./PlotView.css";

import { Badge, Table } from "reactstrap";
import { HIPHEN_GREEN, THEME } from "../../constants";
import {
  selectFilteredAggregatedFeatures,
  selectMergedTraits,
} from "../../selectors/resultMap";
import { setSelectedPlotId, setSelectedTrait } from "../../actions/resultMap";
import { useDispatch, useSelector } from "react-redux";

import { NULL_GROUP_LABEL } from "../../powerdash/constants";
import { PlotCard } from "../../components/PlotCard/PlotCard";
import PropTypes from "prop-types";
import { PropertyBadge } from "../../components/Badges/PropertyBadge/PropertyBadge";
import { PropertyName } from "../../components/PropertyName/PropertyName";
import { VarietyBadge } from "../../components/Badges/VarietyBadge/VarietyBadge";
import { truncateNumber } from "../../services/utils";
import { useGetPropertyCategory } from "../../hooks/useGetPropertyCategory";
import { useKey } from "react-use";
import { useMemo } from "react";

export const PlotView = ({ feature }) => {
  const [selectedTrait, selectedPlotId] = useSelector(({ resultMap }) => [
    resultMap.selectedTrait,
    resultMap.selectedPlotId,
  ]);

  const aggregatedFeatures = useSelector(selectFilteredAggregatedFeatures);
  const variety = aggregatedFeatures.find(
    ({ group }) => group === feature.group
  );
  const traits = useSelector(selectMergedTraits);
  const getPropertyCategory = useGetPropertyCategory();
  const selectedTraitCategory = getPropertyCategory(
    selectedTrait.technical_name
  );

  const replicates = useMemo(
    () =>
      variety.features.toSorted(
        (a, b) =>
          b.properties[selectedTrait.technical_name] -
          a.properties[selectedTrait.technical_name]
      ),
    [selectedTrait.technical_name, variety.features]
  ).map((feature, _, array) => ({
    ...feature,
    diff:
      feature.properties[selectedTrait.technical_name] -
      array[0].properties[selectedTrait.technical_name],
  }));

  const dispatch = useDispatch();

  const color = variety.modality
    ? THEME.modalityColorHash.hex(variety.modality)
    : HIPHEN_GREEN;

  // Press escape to close PlotView
  useKey("Escape", () => dispatch(setSelectedPlotId(null)));

  return (
    <div className="plot-view powerdash-component" key={variety.group}>
      <div className="w-75 h-100 d-flex flex-column gap-1 variety-content">
        <VarietyBadge color={color} variety={variety} />
        {traits.length > 0 ? (
          <Table
            className="variety-table flex-grow-1 mb-0"
            size="sm"
            borderless
          >
            <thead>
              <tr>
                <th className="w-50"></th>
                <th>
                  <i className="fa fa-long-arrow-down" /> Min
                </th>
                <th>x̄ Mean</th>
                <th>
                  <i className="fa fa-long-arrow-up" /> Max
                </th>
              </tr>
            </thead>
            <tbody className="scrollbar-light">
              {traits.map((trait) => (
                <tr
                  key={trait.technical_name}
                  className={`clickable ${
                    trait.technical_name === selectedTrait.technical_name
                      ? "active"
                      : ""
                  }`}
                  onClick={() => dispatch(setSelectedTrait(trait))}
                >
                  <th className="w-50" scope="row">
                    <PropertyName
                      showIcon
                      showUnit
                      technicalName={trait.technical_name}
                    />
                  </th>
                  <td>
                    {truncateNumber(
                      variety.properties[trait.technical_name]?.min ??
                        NULL_GROUP_LABEL,
                      2
                    )}
                  </td>
                  <td>
                    {truncateNumber(
                      variety.properties[trait.technical_name]?.mean ??
                        NULL_GROUP_LABEL,
                      2
                    )}
                  </td>
                  <td>
                    {truncateNumber(
                      variety.properties[trait.technical_name]?.max ??
                        NULL_GROUP_LABEL,
                      2
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <span className="text-end font-style-italic">
            Details will be available when traits are delivered
          </span>
        )}
        <div className="variety-features d-flex flex-row gap-1">
          <div className="d-flex flex-column w-50 gap-1">
            <span className="font-weight-bold">
              Replicates ({variety.features.length})
            </span>
            <div className="d-flex flex-column gap-1 overflow-auto scrollbar-light">
              {replicates.map((feature, index) => (
                <Badge
                  key={feature.id}
                  className={`repetition hiphen-badge small d-flex justify-content-between align-items-center elevated clickable ${
                    selectedPlotId === feature.id ? "selected" : ""
                  }`}
                  onClick={() => dispatch(setSelectedPlotId(feature.id))}
                >
                  <span className="repetition-id">{feature.displayId}</span>

                  {selectedTrait.technical_name && (
                    <div className="flex-shrink-1 d-flex flex-row gap-1 align-items-center">
                      {index === 0 ? (
                        <Badge
                          color="invalid"
                          style={{ backgroundColor: HIPHEN_GREEN }}
                        >
                          BEST
                        </Badge>
                      ) : (
                        <i>{Number(truncateNumber(feature.diff)) || "="}</i>
                      )}
                      <PropertyBadge
                        hideName
                        technicalName={selectedTrait.technical_name}
                        value={feature.properties[selectedTrait.technical_name]}
                        category={selectedTraitCategory}
                      />
                    </div>
                  )}
                </Badge>
              ))}
            </div>
          </div>
        </div>
      </div>
      <PlotCard feature={feature} />
    </div>
  );
};

PlotView.propTypes = {
  feature: PropTypes.object.isRequired,
};
