import { PlotImage } from "../PlotImage/PlotImage";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const ImageHistory = ({ feature }) => {
  const [trial, trialDate] = useSelector(({ resultMap }) => [
    resultMap.trial,
    resultMap.trial_date,
  ]);

  return (
    <div className="image-history gap-1">
      {trial.trial_dates.map((date) => (
        <PlotImage
          key={date}
          date={date}
          feature={feature}
          active={date === trialDate}
        />
      ))}
      <div className="scroll-end font-style-italic">
        <i className="fa fa-check-circle" /> History begins here
      </div>
    </div>
  );
};

ImageHistory.propTypes = {
  feature: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};
