import "./SelectionFloatingCard.css";

import { useDispatch, useSelector } from "react-redux";

import { BlackListWhiteList } from "../../../advancedFiltering/blackListWhiteList/BlackListWhiteList";
import { FloatingCard } from "../../../../../../components/floatingCard/FloatingCard";
import { HELP_TOOLTIPS } from "../../../../../../helpTooltips/helpTooltips";
import { HelpTooltip } from "../../../HelpTooltip/HelpTooltip";
import PropTypes from "prop-types";
import { addFocusedVarieties } from "../../../../../../actions/resultMap";
import { useGetGroupColor } from "../../../../../../hooks/useGetGroupColor";
import { useState } from "react";

const DISPLAY_LIMIT = 200;

export const SelectionFloatingCard = ({ plots }) => {
  const [visible, setVisible] = useState(false);
  const [previousPlots, setPreviousPlots] = useState(plots);

  const selectedVarieties = Array.from(
    new Set(plots.flatMap(({ group }) => group))
  );

  const focusedVarieties = useSelector(
    ({ resultMap }) => resultMap.focusedVarieties
  );
  if (previousPlots !== plots) {
    setVisible(true);
    setPreviousPlots(plots);
  }

  const dispatch = useDispatch();

  const getColor = useGetGroupColor();

  return (
    <>
      {visible && (
        <FloatingCard hide={() => setVisible(false)}>
          <span className="selection-floating-card-title">
            <span className="me-1">
              <b>{selectedVarieties.length}</b> varieties selected from chart
            </span>
            <HelpTooltip helpTooltip={HELP_TOOLTIPS.SELECTION_FLOATING_CARD} />
          </span>
          <div className="selection-floating-card-content">
            {selectedVarieties
              .toSorted()
              .slice(0, DISPLAY_LIMIT)
              .map((variety) => (
                <div
                  className="selection-floating-card-content-item clickable"
                  style={
                    focusedVarieties.includes(variety)
                      ? {
                          background: getColor(variety),
                        }
                      : {}
                  }
                  onClick={() => dispatch(addFocusedVarieties([variety]))}
                  key={variety}
                >
                  <div className="d-flex justify-content-between">
                    <span>
                      <i className="fa-solid fa-dna" /> {variety}
                    </span>
                    <BlackListWhiteList group={variety} />
                  </div>
                </div>
              ))}
            {selectedVarieties.length > DISPLAY_LIMIT && (
              <span>+ {selectedVarieties.length - DISPLAY_LIMIT}</span>
            )}
          </div>
        </FloatingCard>
      )}
    </>
  );
};

SelectionFloatingCard.propTypes = {
  plots: PropTypes.array.isRequired,
};
