import { HIPHEN_GREEN, THEME } from "../../../../../constants";

import { COMMON_PLOT_CONFIG } from "../../../../constants";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { truncateNumber } from "../../../../../services/utils";
import { useMemo } from "react";

const PLOT_STYLE = {
  width: "100%",
  height: "100%",
};

const layout = {
  autosize: true,
  margin: { l: 120, r: 0, t: 0, b: 25 },
  plot_bgcolor: "transparent",
  paper_bgcolor: "white",
  xaxis: {
    range: [0, 110],
    gridcolor: THEME.indicators,
    fixedrange: true,
  },
  yaxis: {
    gridcolor: "transparent",
    categoryorder: "total ascending",
    fixedrange: true,
  },
  font: { color: THEME.indicators, size: 15 },

  colorway: [HIPHEN_GREEN],
};

const config = {
  toImageButtonOptions: {
    filename: "Components explained variance",
  },
  ...COMMON_PLOT_CONFIG,
};

export const BarChart = ({ computedPCA, selectedComponents }) => {
  const displayData = useMemo(() => {
    if (!computedPCA) return [];
    const x = [];
    const y = [];
    const color = [];
    computedPCA.components.forEach(({ name, variance }, index) => {
      x.push(truncateNumber(variance * 100, 1));
      y.push(name);
      color.push(
        selectedComponents.includes(index) ? HIPHEN_GREEN : `${HIPHEN_GREEN}99`
      );
    });
    return [
      {
        x,
        y,
        type: "bar",
        orientation: "h",
        hoverinfo: "none",
        marker: { color },
      },
    ];
  }, [computedPCA, selectedComponents]);

  return (
    <Plot
      useResizeHandler
      data={displayData}
      layout={layout}
      config={config}
      style={PLOT_STYLE}
    />
  );
};

BarChart.propTypes = {
  computedPCA: PropTypes.shape({
    components: PropTypes.arrayOf(
      PropTypes.shape({
        variance: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
  selectedComponents: PropTypes.arrayOf(PropTypes.number).isRequired,
};
