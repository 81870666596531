import {
  ANALYTICS_EVENTS,
  HIPHEN_GREEN,
  THEME,
} from "../../../../../constants";
import { COMMON_PLOT_CONFIG, NULL_GROUP_LABEL } from "../../../../constants";
import { useCallback, useMemo, useState } from "react";

import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { PropertySelect } from "../../../../components/PropertySelect/PropertySelect";
import ReactSlider from "react-slider";
import { SelectionFloatingCard } from "../../../components/charts/ComparativeScatter/SelectionFloatingCard/SelectionFloatingCard";
import { getAttributeColor } from "../../../../utils";
import { useGetPropertyCategory } from "../../../../../hooks/useGetPropertyCategory";
import { useTracking } from "../../../../../analytics";

const PLOT_STYLE = { width: "100%", height: "100%" };

export const PCAScatter = ({
  computedPCA,
  selectedComponents,
  clickedPlots,
  setClickedPlots,
}) => {
  const [selectedPlots, setSelectedPlots] = useState([]);
  const [scatterOpacity, setScatterOpacity] = useState(0.8);

  const [selectedAttribute, setSelectedAttribute] = useState(null);

  const getPropertyCategory = useGetPropertyCategory();
  const [selectedAttributeName, selectedAttributeDate] = selectedAttribute
    ? selectedAttribute.split("@")
    : [];
  const selectedAttributeCategory = useMemo(
    () => getPropertyCategory(selectedAttributeName),
    [getPropertyCategory, selectedAttributeName]
  );

  const plotData = useMemo(() => {
    // No grouping to do if attribute is numeric
    let groupedData = {
      [selectedAttribute]: computedPCA.plotValues,
    };
    if (selectedAttributeCategory.attribute)
      groupedData = Object.groupBy(computedPCA.plotValues, ({ features }) => {
        // Return first value within features to not be undefined
        for (const feature of features)
          if (feature.properties[selectedAttributeName] != null)
            return feature.properties[selectedAttributeName];

        return null;
      });

    return Object.entries(groupedData).map(([name, values]) => {
      const x = [];
      const y = [];
      const text = [];
      const colorValues = []; // Used by plotly color scale if data is numeric
      values.forEach(({ features, values }) => {
        x.push(values[selectedComponents[1]]);
        y.push(values[selectedComponents[0]]);
        text.push(features[0].displayId);

        const feature = selectedAttributeDate
          ? features.find(({ date }) => date === selectedAttributeDate)
          : features[0];
        colorValues.push(feature.properties[selectedAttributeName]);
      });

      // If data is not numeric, use attribute colors for non-null else use colorscale
      const markerColor = selectedAttributeCategory.attribute
        ? name !== "null" && name !== "undefined"
          ? getAttributeColor(name)
          : HIPHEN_GREEN
        : colorValues;

      return {
        x,
        y,
        text,
        mode: "markers",
        type: "scattergl",
        name: name !== "null" ? name : NULL_GROUP_LABEL,
        hovertemplate: "Plot %{text}<br><i>Click to explore</i><extra></extra>",
        textposition: "top",
        marker: {
          color: markerColor,
          colorscale: "Viridis",
          showscale: !selectedAttributeCategory.attribute,
          size: 7.5,
          opacity: scatterOpacity,
        },
      };
    });
  }, [
    computedPCA,
    selectedAttributeCategory.attribute,
    selectedAttribute,
    scatterOpacity,
    selectedComponents,
    selectedAttributeName,
    selectedAttributeDate,
  ]);

  const layout = useMemo(
    () => ({
      autosize: true,
      showlegend:
        selectedAttribute !== null && selectedAttributeCategory.attribute,
      dragmode: "select",
      margin: {
        r: 20,
        t: 20,
        l: 70,
        b: 80,
      },
      annotations: clickedPlots.map((plot) => ({
        x: plot.values[selectedComponents[1]],
        y: plot.values[selectedComponents[0]],
        text: plot.displayId,
      })),
      xaxis: {
        title: computedPCA.components[selectedComponents[1]].name,
        fixedrange: true,
      },
      yaxis: {
        title: computedPCA.components[selectedComponents[0]].name,
        fixedrange: true,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "white",

      font: { color: THEME.indicators, size: 15 },
    }),
    [
      selectedAttribute,
      selectedAttributeCategory.attribute,
      clickedPlots,
      computedPCA.components,
      selectedComponents,
    ]
  );

  const config = useMemo(
    () => ({
      toImageButtonOptions: {
        filename: `PCAscatter ${selectedAttribute ?? ""}`.trim(),
      },
      ...COMMON_PLOT_CONFIG,
    }),
    [selectedAttribute]
  );

  const { trackEvent } = useTracking();

  const handleSelection = useCallback(
    (event) => {
      if (!event || event.points.length === 0) return;
      trackEvent(ANALYTICS_EVENTS.SELECT_FROM_PCA_SCATTER);
      const selectedFeatures = event.points.map(
        (point) => computedPCA.plotValues[point.pointIndex].features[0]
      );
      setSelectedPlots(selectedFeatures);
    },
    [computedPCA, trackEvent]
  );

  const handleClick = useCallback(
    (event) => {
      if (!event || event.points.length === 0) return;
      trackEvent(ANALYTICS_EVENTS.SELECT_FROM_PCA_SCATTER);
      setClickedPlots((current) => {
        const plot = computedPCA.plotValues.find(
          ({ displayId }) => displayId === event.points[0].text
        );
        if (current.includes(plot))
          return current.filter(({ id }) => id !== plot.id);
        if (current.length === 3) return current;
        return [...current, plot];
      });
    },
    [computedPCA.plotValues, setClickedPlots, trackEvent]
  );

  return (
    <>
      <div className="position-relative">
        <SelectionFloatingCard plots={selectedPlots} />
      </div>
      {computedPCA && (
        <Plot
          data={plotData}
          layout={layout}
          onSelected={handleSelection}
          onClick={handleClick}
          config={config}
          style={PLOT_STYLE}
        />
      )}
      <div className="d-flex gap-2 p-1 align-items-center">
        <i className="fa-solid fa-palette discrete-icon" />
        <PropertySelect
          multiDate
          className="flex-grow-1"
          menuPlacement="top"
          value={selectedAttribute}
          onChange={(value) =>
            value
              ? setSelectedAttribute(value.value)
              : setSelectedAttribute(null)
          }
          placeholder="Color by trait or attribute"
        />
        <i className="fa-solid fa-eye discrete-icon" />
        <div className="flex-grow-1">
          <ReactSlider
            value={scatterOpacity}
            step={0.01}
            min={0}
            max={1}
            onAfterChange={setScatterOpacity}
            className="opacity-slider-track"
            thumbClassName="opacity-slider-thumb"
            withTracks={false}
            renderThumb={(props, state) => {
              const { key, ...rest } = props;
              return (
                <div key={key} {...rest}>
                  {state.valueNow}
                </div>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

PCAScatter.propTypes = {
  computedPCA: PropTypes.object,
  selectedComponents: PropTypes.arrayOf(PropTypes.number).isRequired,
  clickedPlots: PropTypes.arrayOf(PropTypes.object).isRequired,
  setClickedPlots: PropTypes.func.isRequired,
};
