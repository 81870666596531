import { Badge, Button, ListGroupItem } from "reactstrap";

import PropTypes from "prop-types";
import classNames from "classnames";

/* Item of list
 *  Displays one element name and associated controls
 */
export const FocusListItem = ({
  name,
  switchVisible,
  isControl,
  switchFavorite,
  onRemove,
  visualIndex,
  color,
  isFavorite,
}) => {
  // Classes for pin btn switch on/off
  const pinIconClasses = classNames("smol-btn fa fa-lg", {
    "fa-star golden": isFavorite,
    "fa-star-o": !isFavorite,
  });
  // Classes for gen
  const genNameClasses = classNames("focus-list-item-name gap-1", {
    visualized: visualIndex !== -1,
  });
  return (
    <ListGroupItem className="focus-list-item">
      {/* If visualIndex !== -1 means the item is associated to a visualizer,
       * in this case shows the associated index in a colored badge
       * else shows a round indicator of the right color
       * color is based on index in list, corresponds to the color on line chart (theme.extendedPalette)
       */}
      {/* Button pin */}
      <div className="focus-list-item-controls">
        <i
          className={pinIconClasses}
          aria-hidden="true"
          onClick={switchFavorite}
          data-tooltip-id="tooltip"
          data-tooltip-content={
            isFavorite ? "Remove from favorites" : "Add to favorites"
          }
          data-tooltip-place="top"
        />
      </div>

      <Button
        className={genNameClasses}
        title={name}
        style={{
          background: color,
          border: "none",
          paddingTop: "2px",
          paddingBottom: "2px",
          color: "black",
          boxShadow: `0 10px 20px -8px ${color}`,
        }}
        onClick={switchVisible}
      >
        <i className="fa-solid fa-dna" /> {name}
      </Button>
      {visualIndex !== -1 && (
        <Badge
          className="index-badge animated"
          // if color is not valid "primary secondary...", the background color is overriden by the background color of the style
          color="invalid background color"
          style={{
            backgroundColor: color,
            boxShadow: `1px 1px 8px -1px ${color}`,
          }}
        >
          {visualIndex + 1}
        </Badge>
      )}
      {isControl && (
        <Badge
          className="hiphen-badge small"
          data-tooltip-id="tooltip"
          data-tooltip-content="Control Variety"
          data-tooltip-place="top"
          data-tooltip-variant="dark"
        >
          <i className="fa fa-pagelines control-variety" /> CV
        </Badge>
      )}

      <i
        className="fa fa-lg fa-close smol-btn remove-button"
        aria-hidden="true"
        data-tooltip-id="tooltip"
        data-tooltip-content="Remove"
        data-tooltip-place="right"
        data-tooltip-variant="dark"
        onClick={onRemove}
      />
    </ListGroupItem>
  );
};

FocusListItem.propTypes = {
  name: PropTypes.string.isRequired,
  switchVisible: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isControl: PropTypes.bool.isRequired,
  switchFavorite: PropTypes.func.isRequired,
  visualIndex: PropTypes.number.isRequired,
  color: PropTypes.string,
  isFavorite: PropTypes.bool.isRequired,
};
