import "./TraitsDatesMatrix.css";

import { Badge, Table } from "reactstrap";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";

import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import { HELP_TOOLTIPS } from "../../../../helpTooltips/helpTooltips";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";
import { OTHER_NUMERICAL_PROPERTIES_GROUP } from "../../../../constants";
import PropTypes from "prop-types";
import { PropertyName } from "../../../../components/PropertyName/PropertyName";
import { useClickAway } from "react-use";
import { useKeyboardShortcut } from "../../../../hooks/useKeyboardShortcut";
import { useSelector } from "react-redux";
import { useTraitGroups } from "../../useTraitGroups";

const CheckIndicator = ({ checked, isImported }) => {
  return (
    <i
      className={`check-indicator ${isImported ? "imported" : ""} ${
        checked
          ? "fa-solid fa-circle-check checked"
          : "fa-regular fa-circle discrete-icon"
      }`}
    />
  );
};

CheckIndicator.propTypes = {
  checked: PropTypes.bool.isRequired,
  isImported: PropTypes.bool,
};

/*
 * Expandable component table with traits as rows and dates as columns
 * to be used to select traits and, if expanded traits at each date
 */
export const TraitsDatesMatrix = ({
  traitsWithDates,
  selectedDatesForTraits,
  setSelectedDatesForTraits,
}) => {
  const [expanded, setExpanded] = useState(false);
  const refreshingFeaturesByDate = useSelector(
    ({ resultMap }) => resultMap.refreshingFeaturesByDate
  );

  /* All available dates within traitsWithDates */
  const allDates = useMemo(
    () =>
      Array.from(
        new Set(traitsWithDates.flatMap((trait) => Array.from(trait.dates)))
      )
        .filter((date) => date) // Remove null date from display
        .toSorted((a, b) => new Date(a) - new Date(b)),
    [traitsWithDates]
  );

  const traitGroups = useTraitGroups(traitsWithDates);

  /* Helper function to check if row is partially selected */
  const traitIsSelected = (technicalName) =>
    selectedDatesForTraits[technicalName].size > 0;

  /* Helper function to check if column is partially selected */
  const dateIsSelected = (date) =>
    Object.values(selectedDatesForTraits).some((dates) => dates.has(date));

  /* Full row selection */
  const selectAllDates = (technicalName) => {
    if (traitIsSelected(technicalName))
      setSelectedDatesForTraits({
        ...selectedDatesForTraits,
        [technicalName]: new Set(),
      });
    else
      setSelectedDatesForTraits({
        ...selectedDatesForTraits,
        [technicalName]: traitsWithDates.find(
          (trait) => trait.technicalName === technicalName
        ).dates,
      });
  };

  /* Full column selection */
  const selectAllTraits = (date) => {
    if (dateIsSelected(date)) {
      setSelectedDatesForTraits(
        Object.entries(selectedDatesForTraits).reduce((acc, [key, value]) => {
          const newSet = new Set(value);
          newSet.delete(date);
          return { ...acc, [key]: newSet };
        }, {})
      );
    } else {
      const traits = traitsWithDates.filter((trait) => trait.dates.has(date));
      setSelectedDatesForTraits(
        Object.entries(selectedDatesForTraits).reduce((acc, [key, value]) => {
          const newSet = new Set(value);
          if (traits.some((trait) => trait.technicalName === key))
            newSet.add(date);
          return {
            ...acc,
            [key]: newSet,
          };
        }, {})
      );
    }
  };

  /*  Change state switch of current cell */
  const selectMatrixCell = useCallback(
    (technicalName, date) => {
      const newSet = new Set(selectedDatesForTraits[technicalName]);
      if (newSet.has(date)) newSet.delete(date);
      else newSet.add(date);
      setSelectedDatesForTraits({
        ...selectedDatesForTraits,
        [technicalName]: newSet,
      });
    },
    [selectedDatesForTraits, setSelectedDatesForTraits]
  );

  const componentRef = useRef();
  useClickAway(componentRef, () => setExpanded(false));
  useKeyboardShortcut("d", () => setExpanded((curr) => !curr));

  return (
    <div
      ref={componentRef}
      className={`powerdash-component traits-dates-matrix ${
        expanded ? "matrix-expanded" : ""
      }`}
    >
      {traitsWithDates.length === 0 ? (
        <ComponentPlaceholder
          icon={
            refreshingFeaturesByDate
              ? "fa-solid fa-gear fa-spin"
              : "fa-solid fa-list-ul"
          }
          text={
            refreshingFeaturesByDate ? "Loading all dates to calculate PCA" : ""
          }
        />
      ) : (
        <Table hover borderless>
          <thead>
            <tr>
              <td className="th-title">
                <div className="d-flex align-items-center justify-content-between text-ellipsis gap-1">
                  <span>
                    <i className="fa-solid fa-right-to-bracket me-1 discrete-icon" />
                    PCA Input{" "}
                    <HelpTooltip helpTooltip={HELP_TOOLTIPS.PCA_INPUT} />
                  </span>
                  {allDates.length > 1 && (
                    <Badge
                      className="hiphen-badge clickable"
                      onClick={() => setExpanded(!expanded)}
                    >
                      <i className="fa-solid fa-calendar me-1 discrete-icon" />
                      {expanded ? "Close date selection" : "Select dates"}
                    </Badge>
                  )}
                </div>
              </td>
              {expanded &&
                allDates.map((date) => (
                  <th key={date} onClick={() => selectAllTraits(date)}>
                    <div className="d-flex align-items-center justify-content-center gap-1">
                      <CheckIndicator checked={dateIsSelected(date)} />
                      <span>{date}</span>
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {traitGroups.map(({ Traits, name, icon }) => (
              <Fragment key={name}>
                <tr className="separator-row">
                  <td>
                    <span className="d-flex align-items-center gap-1">
                      <img src={icon} alt="" width={24} /> {name}
                    </span>
                  </td>
                </tr>
                {Traits.map(({ technicalName, traitGroup, dates }) => (
                  <tr key={technicalName}>
                    <td onClick={() => selectAllDates(technicalName)}>
                      <span className="d-flex align-items-center gap-1">
                        <CheckIndicator
                          checked={traitIsSelected(technicalName)}
                          isImported={
                            traitGroup.name === OTHER_NUMERICAL_PROPERTIES_GROUP
                          }
                        />
                        <PropertyName showIcon technicalName={technicalName} />
                      </span>
                    </td>
                    {expanded &&
                      allDates.map((date) =>
                        dates.has(date) ? (
                          <td
                            key={date}
                            onClick={() =>
                              selectMatrixCell(technicalName, date)
                            }
                          >
                            <CheckIndicator
                              checked={selectedDatesForTraits[
                                technicalName
                              ].has(date)}
                            />
                          </td>
                        ) : (
                          <td key={date} className="no-date"></td>
                        )
                      )}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

TraitsDatesMatrix.propTypes = {
  traitsWithDates: PropTypes.arrayOf(
    PropTypes.shape({
      technicalName: PropTypes.string.isRequired,
      dates: PropTypes.instanceOf(Set).isRequired,
    }).isRequired
  ).isRequired,
  selectedDatesForTraits: PropTypes.objectOf(
    PropTypes.instanceOf(Set).isRequired
  ).isRequired,
  setSelectedDatesForTraits: PropTypes.func.isRequired,
};
