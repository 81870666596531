import { COMMON_PLOT_CONFIG } from "../../../../constants";
import ColorHash from "color-hash";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { THEME } from "../../../../../constants";
import { selectTraitsIndexedOnTechnicalName } from "../../../../../selectors/traits";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const PLOT_STYLE = { width: "100%", height: "100%" };

const layout = {
  autosize: true,
  responsive: true,
  dragmode: "select",
  margin: {
    r: 30,
    t: 10,
    l: 20,
    b: 30,
  },
  plot_bgcolor: "transparent",
  paper_bgcolor: "white",
  polar: {
    radialaxis: {
      visible: false,
      range: [0, 1],
    },

    angularaxis: {
      showticklabels: false,
      nticks: 6,
    },
  },

  font: { color: THEME.indicators, size: 15 },
};

const config = {
  toImageButtonOptions: {
    filename: "PCA Circle chart",
  },
  ...COMMON_PLOT_CONFIG,
};

export const CircleChart = ({
  computedPCA,
  selectedComponents,
  showLabels,
}) => {
  const traitsObject = useSelector(selectTraitsIndexedOnTechnicalName);
  const displayData = useMemo(() => {
    const colorHash = new ColorHash({
      hue: { min: 0, max: 360 },
      saturation: 0.8,
      lightness: 0.45,
    });

    return computedPCA.columns.map((column, index) => {
      const x = computedPCA.loadings[selectedComponents[1]][index];
      const y = computedPCA.loadings[selectedComponents[0]][index];
      const r = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
      let theta = Math.atan2(y, x);
      const thetaDegrees = theta * (180 / Math.PI);

      const splitted = column[0].split("|");
      const prefix = splitted.shift();
      const classes = splitted.map((label) => label.replaceAll("_", " "));

      const trait = traitsObject[prefix];
      const name = `${trait?.name ?? prefix} ${classes.join(" ")}`.trim();

      const baseColor = colorHash.hex(name);
      const date = column[1] ?? "";
      return {
        type: "scatterpolar",
        mode: showLabels ? "lines+markers+text" : "lines+markers",
        hovertemplate: `<b>${name}</b> <i>${date}</i><extra></extra>`,
        text: ["", `${name}: <b>${date}</b>`],
        marker: { color: baseColor },
        legendgroup: name,
        legendgrouptitle: {
          text: name,
          font: {
            weight: "bold",
            size: 14,
            color: baseColor,
          },
        },
        r: [0, r],
        theta: [0, thetaDegrees],
        name: `${date}`,
      };
    });
  }, [
    computedPCA.columns,
    computedPCA.loadings,
    selectedComponents,
    traitsObject,
    showLabels,
  ]);

  return (
    <Plot
      data={displayData}
      layout={layout}
      config={config}
      style={PLOT_STYLE}
    />
  );
};

CircleChart.propTypes = {
  computedPCA: PropTypes.shape({
    components: PropTypes.arrayOf(
      PropTypes.shape({
        variance: PropTypes.number.isRequired,
      })
    ).isRequired,
    loadings: PropTypes.array.isRequired,
  }),
  selectedComponents: PropTypes.arrayOf(PropTypes.number).isRequired,
  showLabels: PropTypes.bool.isRequired,
};
