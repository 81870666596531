import {
  DEFAULT_BLACKLISTED_TRAITS,
  FEATURE_PROPERTY_CATEGORIES,
} from "../../../constants";
import {
  selectFeaturesByDateWithAUCData,
  selectFeaturesWithAUCData,
} from "../../../selectors/resultMap";
import { useMemo, useState } from "react";

import { Badge } from "reactstrap";
import PropTypes from "prop-types";
import { PropertyName } from "../../../components/PropertyName/PropertyName";
import ReactSelect from "react-select";
import { selectTraitsIndexedOnTechnicalName } from "../../../selectors/traits";
import { useGetPropertyCategory } from "../../../hooks/useGetPropertyCategory";
import { useSelector } from "react-redux";

const SearchLabel = ({ data, inputValue }) =>
  data.value ? (
    <PropertyName showIcon technicalName={data.value} highlight={inputValue} />
  ) : (
    data.label
  );

SearchLabel.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  inputValue: PropTypes.string,
};

export const PropertySelect = (props) => {
  const { onChange, value, placeholder, autoFocus, multiDate, ...rest } = props;
  const [focused, setFocused] = useState(autoFocus);

  // If multiDate, then options will be generated using featuresByDate
  const features = useSelector(
    multiDate ? selectFeaturesByDateWithAUCData : selectFeaturesWithAUCData
  );

  const getPropertyCategory = useGetPropertyCategory();
  const traitsObject = useSelector(selectTraitsIndexedOnTechnicalName);

  const [propertiesKeys, propertyCategories] = useMemo(() => {
    const propertiesKeys = new Set();
    const propertyCategories = {};

    features.forEach(({ properties, date }) => {
      Object.keys(properties).forEach((key) => {
        // Store categories of keys in object to avoid redundant calls
        if (!propertyCategories[key])
          propertyCategories[key] = getPropertyCategory(key);

        /* If multiDate then we want to concatenate @date to each hiphen trait */
        propertiesKeys.add(
          multiDate &&
            propertyCategories[key] === FEATURE_PROPERTY_CATEGORIES.HIPHEN
            ? `${key}@${date}`
            : key
        );
      });
    });
    return [propertiesKeys, propertyCategories];
  }, [features, getPropertyCategory, multiDate]);

  const options = useMemo(
    () =>
      Array.from(propertiesKeys)
        .filter(
          (key) => !DEFAULT_BLACKLISTED_TRAITS.includes(key.toLowerCase())
        )
        .map((key) => {
          const basekey = key.split("@")[0];
          return {
            label: traitsObject[basekey]?.name ?? key,
            value: key,
            category: propertyCategories[basekey],
          };
        })
        .toSorted((a, b) => a.category.priority - b.category.priority),
    [propertiesKeys, propertyCategories, traitsObject]
  );

  const groupedOptions = Object.values(
    options.reduce((acc, option) => {
      const categoryLabel = option.category.label;

      if (!acc[categoryLabel])
        acc[categoryLabel] = { label: categoryLabel, options: [] };

      acc[categoryLabel].options.push({
        label: option.label,
        value: option.value,
      });

      return acc;
    }, {})
  );

  return focused ? (
    <ReactSelect
      {...rest}
      isClearable
      defaultMenuIsOpen
      autoFocus
      options={groupedOptions}
      placeholder={placeholder}
      value={options.find((opt) => opt.value === value)}
      onChange={(value) => {
        onChange(value);
        setFocused(false);
      }}
      onBlur={() => setFocused(false)}
      components={{
        Option: ({ innerProps, data, selectProps }) => (
          <div className="p-2 clickable" {...innerProps}>
            <SearchLabel data={data} inputValue={selectProps.inputValue} />
          </div>
        ),
        SingleValue: SearchLabel,
      }}
    />
  ) : (
    <Badge
      onClick={() => setFocused(true)}
      className={`hiphen-badge elevated clickable ${props.className || ""}`}
    >
      {value ? (
        <PropertyName showIcon technicalName={value} />
      ) : (
        <span className="font-style-italic">{placeholder}</span>
      )}
    </Badge>
  );
};

PropertySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  multiDate: PropTypes.bool,
  // + ReactSelect props
};
