import { Badge } from "reactstrap";
import { ComponentTitle } from "../../../components/componentTitle/componentTitle";
import { PropTypes } from "prop-types";

export const PCASummary = ({ computedPCA }) => {
  return (
    <div className="powerdash-component flex-column gap-1">
      <ComponentTitle
        icon="fa-solid fa-circle-nodes"
        title="Principal component analysis"
        topRightContent={
          <a
            href="https://en.wikipedia.org/wiki/Principal_component_analysis"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-solid fa-arrow-up-right-from-square discrete-icon" />
          </a>
        }
      />
      <span>
        <Badge className="hiphen-badge active small">
          {computedPCA.components.length} Principal components
        </Badge>{" "}
        extracted, reducing{" "}
        <span>
          <span className="font-weight-bold">{computedPCA.columns.length}</span>{" "}
          selected dimensions
        </span>
      </span>
    </div>
  );
};

PCASummary.propTypes = {
  computedPCA: PropTypes.shape({
    components: PropTypes.arrayOf(
      PropTypes.shape({
        variance: PropTypes.number.isRequired,
      })
    ).isRequired,
    columns: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  }),
};
