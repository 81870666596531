import { Button, FormGroup, Input, Label } from "reactstrap";

import { ComponentTitle } from "../../../../components/componentTitle/componentTitle";
import { MATRIX_FILL_METHODS } from "../../../../../constants";
import PropTypes from "prop-types";
import ReactSlider from "react-slider";

export const PCASettings = ({
  options,
  setOptions,
  handleCompute,
  disableCompute,
}) => (
  <div className="powerdash-component pca-settings d-flex flex-column">
    <ComponentTitle icon="fa-solid fa-gear" title="PCA Settings" />
    <div className="d-flex d-flex flex-column gap-2 p-2 overflow-auto flex-grow-1">
      <div className="d-flex align-items-center justify-content-between">
        <span>Minimum explained variance</span>
        <span>{(options.threshold * 100).toFixed(0)}%</span>
      </div>
      <ReactSlider
        value={options.threshold}
        step={0.01}
        min={0.01}
        max={1}
        onChange={(value) => setOptions({ ...options, threshold: value })}
        className="cloverfield-slider"
        thumbClassName="cloverfield-slider-thumb"
        trackClassName="cloverfield-slider-track"
      />

      <FormGroup noMargin className="d-flex align-items-baseline gap-1">
        <span>Fill method</span>
        <div className="flex-grow-1" />
        <FormGroup
          check
          data-tooltip-id="tooltip"
          data-tooltip-content="Drop rows containing null values"
        >
          <Label
            check
            className="small informative d-flex align-items-baseline gap-1 clickable"
          >
            <Input
              type="radio"
              checked={options.fillMethod === MATRIX_FILL_METHODS.DROP}
              onChange={() =>
                setOptions({ ...options, fillMethod: MATRIX_FILL_METHODS.DROP })
              }
            />
            DROP
          </Label>
        </FormGroup>
        <FormGroup
          check
          data-tooltip-id="tooltip"
          data-tooltip-content="Fill null value with column mean"
        >
          <Label
            check
            className="small informative d-flex align-items-baseline gap-1 clickable"
          >
            <Input
              type="radio"
              checked={options.fillMethod === MATRIX_FILL_METHODS.MEAN}
              onChange={() =>
                setOptions({ ...options, fillMethod: MATRIX_FILL_METHODS.MEAN })
              }
            />
            MEAN
          </Label>
        </FormGroup>
      </FormGroup>
      <div className="d-flex align-items-center gap-2">
        <FormGroup switch className="d-flex align-items-baseline gap-2">
          <Input
            type="switch"
            checked={options.useFilteredData}
            onChange={() =>
              setOptions({
                ...options,
                useFilteredData: !options.useFilteredData,
              })
            }
          />
          <Label check>Compute on filtered data</Label>
        </FormGroup>
      </div>
      <div className="d-flex align-items-center gap-2">
        <FormGroup switch className="d-flex align-items-baseline gap-2">
          <Input
            type="switch"
            checked={options.autoCompute}
            onChange={() =>
              setOptions({
                ...options,
                autoCompute: !options.autoCompute,
              })
            }
          />
          <Label check>Compute automatically</Label>
        </FormGroup>
      </div>
    </div>
    {!options.autoCompute && (
      <Button
        disabled={disableCompute}
        onClick={handleCompute}
        className="hiphen-green-button"
      >
        Compute PCA <i className="fa fa-play ms-2" />
      </Button>
    )}
  </div>
);

PCASettings.propTypes = {
  options: PropTypes.shape({
    threshold: PropTypes.number.isRequired,
    useFilteredData: PropTypes.bool.isRequired,
    fillMethod: PropTypes.oneOf(Object.values(MATRIX_FILL_METHODS)).isRequired,
    autoCompute: PropTypes.bool.isRequired,
  }).isRequired,
  setOptions: PropTypes.func.isRequired,
  handleCompute: PropTypes.func.isRequired,
  disableCompute: PropTypes.bool.isRequired,
};
