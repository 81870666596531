import { AdvancedFiltering } from "./AdvancedFiltering";
import { Offcanvas } from "reactstrap";
import { OffcanvasTabs } from "../../../../components/OffcanvasTabs/OffcanvasTabs";
import PropTypes from "prop-types";
import { selectNumericalPropertiesMetricsAndAggregatedFeatures } from "../../../../selectors/resultMap";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const OffCanvasWrapper = ({ isOffcanvasOpen, toggleOffcanvas }) => {
  const [trial, trialDate, filteringProfile, features] = useSelector(
    ({ resultMap }) => [
      resultMap.trial,
      resultMap.trial_date,
      resultMap.filteringProfile,
      resultMap.features,
    ]
  );

  const [, numericalPropertiesMetrics] = useSelector(
    selectNumericalPropertiesMetricsAndAggregatedFeatures
  );

  // Key given to advanced filtering to reset component whenever the date changes
  // and/or the scope is reset
  const advancedFilteringKey = useMemo(
    () =>
      [
        trialDate,
        filteringProfile.scope,
        filteringProfile.id,
        ...Object.keys(numericalPropertiesMetrics),
      ].join(","),
    [
      trialDate,
      filteringProfile.scope,
      filteringProfile.id,
      numericalPropertiesMetrics,
    ]
  );

  return (
    <>
      {trial && features.length > 0 && (
        <OffcanvasTabs
          isOffcanvasOpen={isOffcanvasOpen}
          toggleOffcanvas={toggleOffcanvas}
        />
      )}
      <Offcanvas
        isOpen={isOffcanvasOpen}
        direction="end"
        backdrop={false}
        className="offcanvas-wrap"
        toggle={toggleOffcanvas}
      >
        <AdvancedFiltering
          key={advancedFilteringKey}
          toggleOffcanvas={toggleOffcanvas}
        />
      </Offcanvas>
    </>
  );
};

OffCanvasWrapper.propTypes = {
  isOffcanvasOpen: PropTypes.bool.isRequired,
  toggleOffcanvas: PropTypes.func.isRequired,
};
