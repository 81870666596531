import {
  DEFAULT_BLACKLISTED_TRAITS,
  DEFAULT_MAP_LAYERS,
  DEFAULT_TRAIT_STYLE,
  OTHER_NUMERICAL_PROPERTIES_GROUP,
  RESERVED_PROPERTY_KEYS,
} from "../constants";

import { CONTROL_VARIETY_YES } from "../powerdash/constants";
import { boolStringToInt } from "../services/utils";
import chroma from "chroma-js";
import { createSelector } from "reselect";
import otherPropertiesIcon from "../static/img/other_properties.png";

export const selectFeaturesWithAUCData = createSelector(
  (state) => state.resultMap.features,
  (state) => state.resultMap.aucData,
  (features, aucData) => {
    if (!aucData.traits.length) return features;
    return features.map((feature) => ({
      ...feature,
      properties: {
        ...feature.properties,
        ...aucData.featureData[feature.properties.plot_id],
      },
    }));
  }
);

// Adds AUC data to each corresponding feature in featuresByDate
export const selectFeaturesByDateWithAUCData = createSelector(
  (state) => state.resultMap.featuresByDate,
  (state) => state.resultMap.aucData,
  (features, aucData) => {
    if (!aucData.traits.length) return features;
    return features.map((feature) => ({
      ...feature,
      properties: {
        ...feature.properties,
        ...aucData.featureData[feature.properties.plot_id],
      },
    }));
  }
);

const filterFeatures = (
  features,
  selectedLayer,
  selectedExperiments,
  distinctExperiments,
  distinctLayers,
  selectedModalities,
  distinctModalities,
  filteringProfile,
  selectedAttributes
) => {
  // Use user-defined filtering profile if it is defined
  if (filteringProfile.scope) {
    selectedExperiments = filteringProfile.scope.experiments;
    selectedModalities = filteringProfile.scope.modalities;
    selectedLayer = filteringProfile.scope.layer;
    selectedAttributes = filteringProfile.scope.attributes;
  }

  // Do not filter on layer if less than 2 layers
  const shouldSkipLayerFilter = distinctLayers.length <= 1;

  // Do not filter on experiments if no experiments, no experiments selected, or all experiments selected
  const shouldSkipExperimentFilter =
    !distinctExperiments.length ||
    (!!selectedExperiments.length &&
      distinctExperiments.length === selectedExperiments.length);

  // Do not filter on modalities if no modalities, no modalities selected, or all modalities selected
  const shouldSkipModalityFilter =
    !distinctModalities.length ||
    (!!selectedModalities.length &&
      distinctModalities.length === selectedModalities.length);

  return features.filter(
    ({ properties: { experiment, layer, modality, ...attributesProps } }) => {
      // Check if layer filter should be applied
      const isSelectedLayer = shouldSkipLayerFilter || selectedLayer === layer;
      if (!isSelectedLayer) return false;

      // Check if experiment filter should be applied
      const isSelectedExperiment =
        shouldSkipExperimentFilter ||
        selectedExperiments.includes(experiment ?? null);

      // Check if modality filter should be applied
      const isSelectedModality =
        shouldSkipModalityFilter ||
        selectedModalities.includes(modality ?? null);

      // Check if attributes match selected attributes
      const shouldExcludeFeature = Object.entries(selectedAttributes).some(
        ([key, values]) => {
          const attributeValue = attributesProps[key] ?? null;

          // Exclude features where the attribute value is not included in the selected values
          return !values.includes(attributeValue);
        }
      );

      return (
        isSelectedExperiment && isSelectedModality && !shouldExcludeFeature
      );
    }
  );
};

export const selectFilteredFeaturesByDate = createSelector(
  selectFeaturesByDateWithAUCData,
  (state) => state.resultMap.filters.layer,
  (state) => state.resultMap.filters.experiments,
  (state) => state.resultMap.distinctExperiments,
  (state) => state.resultMap.distinctLayers,
  (state) => state.resultMap.filters.modalities,
  (state) => state.resultMap.distinctModalities,
  (state) => state.resultMap.filteringProfile,
  (state) => state.resultMap.filters.attributes,
  filterFeatures
);

export const selectFeaturesFilteredOnExperimentAndLayer = createSelector(
  selectFeaturesWithAUCData,
  // Exact properties of filters have to be extracted
  // otherwise min or min filter update would also trigger this selector update
  (state) => state.resultMap.filters.layer,
  (state) => state.resultMap.filters.experiments,
  (state) => state.resultMap.distinctExperiments,
  (state) => state.resultMap.distinctLayers,
  (state) => state.resultMap.filters.modalities,
  (state) => state.resultMap.distinctModalities,
  (state) => state.resultMap.filteringProfile,
  (state) => state.resultMap.filters.attributes,
  filterFeatures
);

// Get unique excluded groups across all dates
export const selectFilteredOutGroupsSet = createSelector(
  (state) => state.resultMap.filteringProfile,
  (filteringProfile) => {
    if (!filteringProfile.scope) return new Set();
    return new Set(
      filteringProfile.filters.flatMap((filter) => filter.excludedGroups) // Concat all excluded groups
    );
  }
);

export const selectExcludedGroupsSet = createSelector(
  (state) => state.resultMap.filteringProfile,
  selectFilteredOutGroupsSet,
  (filteringProfile, filteredOutGroupsSet) => {
    if (!filteringProfile.scope) return new Set();
    return new Set(
      Array.from(filteredOutGroupsSet)
        .concat(filteringProfile.blacklist) // Add all blacklisted groups
        .filter((group) => !filteringProfile.whitelist.includes(group)) // Remove whitelisted groups
    );
  }
);

// Selector to select features by date, filtering excluded groups
export const selectFilteredFeaturesByDateAndExcludedGroups = createSelector(
  selectFilteredFeaturesByDate,
  selectExcludedGroupsSet,
  (features, excludedGroupsSet) =>
    excludedGroupsSet.size === 0
      ? features
      : features.filter((feature) => !excludedGroupsSet.has(feature.group))
);

export const selectSelectedTrialContractInfo = createSelector(
  (state) => state.user.contracts,
  (state) => state.resultMap.trial?.contract_id,
  (contracts, contractId) =>
    contracts.find((contract) => contract.id === contractId)
);

export const selectNumericalPropertiesMetricsAndAggregatedFeatures =
  createSelector(selectFeaturesFilteredOnExperimentAndLayer, (features) => {
    const aggregatedFeatures = {};
    const numericalPropertiesMetrics = {};

    features.forEach((feature) => {
      const { group, properties, modality, genotype } = feature;

      if (!aggregatedFeatures[group])
        aggregatedFeatures[group] = {
          features: [],
          properties: {},
          modality,
          genotype,
          isControl:
            properties.control_variety?.toLowerCase() === CONTROL_VARIETY_YES,
        };

      const groupData = aggregatedFeatures[group];
      groupData.features.push(feature);

      Object.entries(properties).forEach(([key, value]) => {
        if (
          DEFAULT_BLACKLISTED_TRAITS.includes(key.toLowerCase()) ||
          RESERVED_PROPERTY_KEYS.includes(key.toLowerCase())
        )
          return;

        if (typeof value === "number") {
          if (!numericalPropertiesMetrics[key])
            numericalPropertiesMetrics[key] = {
              min: Infinity,
              max: -Infinity,
              sum: 0,
              count: 0,
            };

          numericalPropertiesMetrics[key].min = Math.min(
            numericalPropertiesMetrics[key].min,
            value
          );
          numericalPropertiesMetrics[key].max = Math.max(
            numericalPropertiesMetrics[key].max,
            value
          );
          numericalPropertiesMetrics[key].sum += value;
          numericalPropertiesMetrics[key].count++;

          if (!groupData.properties[key]) {
            groupData.properties[key] = {
              min: value,
              max: value,
              sum: value,
              count: 1, // Count of non null values
            };
          } else {
            groupData.properties[key].min = Math.min(
              groupData.properties[key].min,
              value
            );
            groupData.properties[key].max = Math.max(
              groupData.properties[key].max,
              value
            );
            groupData.properties[key].sum += value;
            groupData.properties[key].count++;
          }
        }
      });
    });

    const aggregatedFeaturesArray = Object.entries(aggregatedFeatures).map(
      ([group, data]) => {
        Object.values(data.properties).forEach((value) => {
          value.mean = value.sum / value.count;
        });
        return {
          group,
          ...data,
        };
      }
    );

    Object.values(numericalPropertiesMetrics).forEach((value) => {
      value.mean = value.sum / value.count;
    });

    return [aggregatedFeaturesArray, numericalPropertiesMetrics];
  });

export const selectMergedTraits = createSelector(
  (state) => state.resultMap.traitsListForMap,
  (state) => state.resultMap.aucData.traits,
  selectNumericalPropertiesMetricsAndAggregatedFeatures,
  (traitsListForMap, aucDataTraits, [, numericalPropertiesMetrics]) => {
    const defaultTraitGroup = {
      uuid: OTHER_NUMERICAL_PROPERTIES_GROUP,
      name: OTHER_NUMERICAL_PROPERTIES_GROUP,
      icon: otherPropertiesIcon,
    };
    const traits = [...traitsListForMap, ...aucDataTraits];
    const otherTraits = Object.keys(numericalPropertiesMetrics)
      .filter((key) => !traits.some((trait) => trait.technical_name === key))
      .map((key) => ({
        name: key,
        technical_name: key,
        technicalName: key,
        traitGroup: defaultTraitGroup,
        style: DEFAULT_TRAIT_STYLE,
      }));
    return traits.concat(otherTraits);
  }
);

export const selectFilteredAggregatedFeatures = createSelector(
  selectNumericalPropertiesMetricsAndAggregatedFeatures,
  selectExcludedGroupsSet,
  ([aggregatedFeatures], excludedGroups) =>
    !excludedGroups.size
      ? aggregatedFeatures
      : aggregatedFeatures.filter(({ group }) => !excludedGroups.has(group))
);

export const selectFilteredFeatures = createSelector(
  selectFilteredAggregatedFeatures,
  (aggregatedFeatures) => {
    return aggregatedFeatures.flatMap(({ features }) => features);
  }
);

export const selectColorScale = createSelector(
  selectFilteredFeatures,
  (state) => state.resultMap.selectedTrait,
  (state) => state.resultMap.colorScaleStep,
  (filteredFeatures, { style, technical_name }, colorScaleStep) => {
    const defaultColorScale = {
      minValue: null,
      maxValue: null,
      step: null,
      func: () => "rgba(255, 255, 255, 0)",
    };

    // If `selectedTrait` not empty then `style` is defined
    if (filteredFeatures.length && style && style.opacity !== false) {
      let minValue = null;
      let maxValue = null;

      // Expect both min and max interval to be defined if `intervalDefault` is true
      if (style.intervalDefault) {
        minValue = style.intervalMin;
        maxValue = style.intervalMax;
      } else {
        // `forEach` prefered to `Math.min`/`Math.max` or `reduce` to prevent performance or stack size problems on very large arrays
        filteredFeatures.forEach((feature) => {
          const value = feature.properties[technical_name];
          if (value != null) {
            if (minValue === null || value < minValue) minValue = value;
            if (maxValue === null || value > maxValue) maxValue = value;
          }
        });

        minValue = boolStringToInt(minValue);
        maxValue = boolStringToInt(maxValue);
      }

      if ((minValue !== 0 && !minValue) || (maxValue !== 0 && !maxValue))
        return defaultColorScale;

      const stepValue = colorScaleStep || style.step;

      return {
        minValue: minValue,
        maxValue: maxValue,
        step: stepValue,
        func: chroma
          .scale(style.color)
          .domain([minValue, maxValue])
          .mode("lrgb")
          .classes(stepValue),
      };
    }
    return defaultColorScale;
  }
);

export const selectControlVarieties = createSelector(
  selectNumericalPropertiesMetricsAndAggregatedFeatures,
  ([aggregatedFeatures]) =>
    aggregatedFeatures
      .filter(({ isControl }) => isControl)
      .map(({ group }) => group)
);

export const selectOrtho = createSelector(
  (state) => state.resultMap.mapLayers,
  (state) => state.resultMap.trial,
  (state) => state.resultMap.trial_date,
  (mapLayers, trial, trialDate) => {
    const orthoLayer = mapLayers.find(
      ({ id }) => id === DEFAULT_MAP_LAYERS.ORTHO_LAYER
    );
    return orthoLayer && trial.ortho_layer[trialDate]?.[orthoLayer.name];
  }
);
