/* eslint-disable react-hooks/exhaustive-deps */
import "./analyticsdashboard.css";

import { Redirect, Route, Switch } from "react-router-dom";
import { hasAnalyticsRole, hasPcaRole } from "../../users/rolesUtil";
import { useDispatch, useSelector } from "react-redux";

import { ANALYTICS_EVENTS } from "../../constants";
import { BreederReport } from "./templates/BreederReport";
import { ComponentPlaceholder } from "../components/componentPlaceholder/componentPlaceholder";
import { Container } from "reactstrap";
import LoadingImg from "../../components/loading";
import { PrincipalComponentAnalysis } from "./templates/PrincipalComponentAnalysis/PrincipalComponentAnalysis";
import { fetchFeaturesByDate } from "../../actions/resultMap";
import { fetchTraitGroups } from "../../actions/traits";
import { useEffect } from "react";
import { useEffectOnce } from "react-use";
import { useTracking } from "../../analytics";

/*
 * Analytics tool: powerful analytics dashboards
 * Single page app embedded in cloverfield to display intelligence and analytics
 * about calculated traits and crops fields.
 * Powerful modular templates can be implemented and embedded here
 */
export const AnalyticsLayout = () => {
  const [
    trial,
    featuresByDate,
    refreshing,
    refreshingFeaturesByDate,
    selectedContract,
    traitsListForMap,
  ] = useSelector((state) => [
    state.resultMap.trial,
    state.resultMap.featuresByDate,
    state.resultMap.refreshing,
    state.resultMap.refreshingFeaturesByDate,
    state.resultMap.selectedContract,
    state.resultMap.traitsListForMap,
  ]);

  const traitGroupsFetched = useSelector(
    (state) => state.traits.traitGroupsFetched
  );

  const dispatch = useDispatch();

  useEffectOnce(() => {
    if (!traitGroupsFetched) dispatch(fetchTraitGroups());
  });

  const { trackEvent } = useTracking();

  useEffect(() => {
    if (trial) {
      trackEvent(ANALYTICS_EVENTS.ANALYTICS_IS_BEING_RENDERED);
      // Fetch features by date on trial change
      if (featuresByDate.length === 0 && !refreshingFeaturesByDate)
        dispatch(fetchFeaturesByDate({ id: trial.id }));
    }
  }, [trial, featuresByDate, refreshingFeaturesByDate, dispatch, trackEvent]);

  return (
    <Container
      fluid
      className="page-content d-flex flex-column powerdash light-theme"
    >
      {/* Change template here */}

      <Switch>
        <Route exact path="/analytics/breeder">
          {trial &&
            (!hasAnalyticsRole(selectedContract.roles) ||
              traitsListForMap.length === 0) && (
              <Redirect to="/analytics/unavailable" />
            )}
          <BreederReport />
        </Route>
        <Route exact path="/analytics/pca">
          {trial &&
            (!hasPcaRole(selectedContract.roles) ||
              traitsListForMap.length === 0) && (
              <Redirect to="/analytics/unavailable" />
            )}
          <PrincipalComponentAnalysis key={featuresByDate} />
        </Route>
        <Route exact path="/analytics/unavailable">
          <ComponentPlaceholder text="Selected Trial is not available for this dashboard" />
        </Route>
        <Redirect to="/analytics/breeder" />
      </Switch>

      {(refreshing || !traitGroupsFetched) && (
        <div className="loading-screen">
          <LoadingImg visible />
        </div>
      )}
    </Container>
  );
};
