import { CUSTOM_DATA_COLOR } from "../constants";

export const HELP_TOOLTIPS = {
  SELECTION_FLOATING_CARD: {
    id: "helper-selection-floating-card",
    title: "Variety selection",
    content: (
      <>
        This is the list of all the <i className="fa-solid fa-dna" />
        <span className="font-weight-bold"> Varieties</span>{" "}
        <span className="font-style-italic">
          (<span className="attribute">genotype</span> ×{" "}
          <span className="attribute">modality</span>)
        </span>{" "}
        of the plots you selected from the chart
      </>
    ),
    tips: [
      <>
        Click the items to add them to your{" "}
        <span className="font-weight-bold">Focused Varieties</span>
      </>,
      <>
        If you have a <span className="font-weight-bold">Filtering scope</span>{" "}
        defined, you can add varieties to{" "}
        <span className="font-weight-bold">
          <i className="fa-solid fa-minus-circle" /> Block list
        </span>{" "}
        and{" "}
        <span className="font-weight-bold">
          <i className="fa-solid fa-shield-halved" /> Allow list
        </span>
      </>,
    ],
  },
  PCA_SCATTER: {
    id: "helper-pca-scatter",
    title: "PCA Projection Chart",
    content: (
      <>
        This chart is a projection of all{" "}
        <span className="font-weight-bold">plots</span> using the selected
        components from computed PCA.
        <br />
        <span className="font-weight-bold">Selected components</span> are
        globally set as context of the dashboard in the top right corner
      </>
    ),
    tips: [
      <>
        Choose an <span className="font-weight-bold">attribute</span> or a{" "}
        <span className="font-weight-bold">trait</span> to{" "}
        <i className="fa-solid fa-palette" /> color plots
      </>,
      <>
        Adjust <i className="fa-solid fa-eye" />{" "}
        <span className="font-weight-bold">opacity</span> of dots
      </>,
      <>
        <span className="font-weight-bold">Click</span> on dots to display their{" "}
        <i className="fa-solid fa-images" />{" "}
        <span className="font-weight-bold">Image history</span> below
      </>,
      <>
        <span className="font-weight-bold">Draw</span> a selection box to select{" "}
        <i className="fa-solid fa-dna" />{" "}
        <span className="font-weight-bold">Varieties</span> from chart
      </>,
    ],
  },
  PCA_CIRCLE: {
    id: "helper-pca-circle",
    title: "Variables Correlation chart",
    content: (
      <div className="d-flex flex-column gap-1">
        <span>
          This chart visualizes relationships between selected traits
          (variables) per date related to each{" "}
          <span className="font-weight-bold">principal component</span>. Each
          trait is represented by a colored vector. The direction shows how
          traits <span className="font-weight-bold">relate statistically</span>.{" "}
          Longer vectors indicate stronger relationships. All vectors start from
          the center and point outward the cricle. <br />
          <br /> Interpreting Vector-Trait Relationships:
        </span>
        <ul>
          <li>
            <span className="font-weight-bold">Same direction</span>: Traits are
            strongly correlated
          </li>
          <li>
            <span className="font-weight-bold">Opposite direction</span>: Traits
            are inversely correlated
          </li>
          <li>
            <span className="font-weight-bold">Perpendicular</span>: Traits are
            not correlated
          </li>
        </ul>
        <span>
          The <span className="font-weight-bold">length</span> of each vector
          reflects the strength or magnitude of the trait's variation. Longer
          vectors indicate a more significant contribution to the observed data
          pattern, while shorter vectors suggest weaker effects.
        </span>
      </div>
    ),
    tips: [
      "Click on legend items to hide them from chart",
      "Double click on dates to display only associated trait",
    ],
  },
  PCA_INPUT: {
    id: "helper-pca-input",
    title: "PCA Input",
    content: (
      <>
        Select the traits to include as variables in PCA computation
        <br />
        Heterogeneities and Legacy traits are deselected by default as they
        might be irrelevant
      </>
    ),
    tips: [
      <>
        Click on{" "}
        <span className="font-weight-bold">
          <i className="fa-solid fa-calendar" /> Select dates
        </span>{" "}
        for a deeper selection of traits delivered at each date{" "}
        <span className="font-style-italic">
          Available if at least 2 dates are delivered
        </span>
      </>,
      <>
        It is possible to upload and select{" "}
        <span className="font-weight-bold" style={{ color: CUSTOM_DATA_COLOR }}>
          <i className="fa-solid fa-upload" /> Custom data
        </span>
      </>,
    ],
  },
};
