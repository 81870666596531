import "./PlotCard.css";

import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FloatingCard } from "../floatingCard/FloatingCard";
import { ImageHistory } from "./ImageHistory";
import { PlotImage } from "../PlotImage/PlotImage";
import { PropTypes } from "prop-types";
import { PropertiesMasonry } from "../PropertiesMasonry/PropertiesMasonry";
import { setSelectedPlotId } from "../../actions/resultMap";
import { useTimeoutFn } from "react-use";

export const PlotCard = ({ feature }) => {
  const [selectedTrait, trialDate] = useSelector(({ resultMap }) => [
    resultMap.selectedTrait,
    resultMap.trial_date,
  ]);

  const [showHistory, setShowHistory] = useState(false);

  // 100ms delay before closing history
  const [isReadyToCloseHistory, cancelCloseHistory, resetCloseHistory] =
    useTimeoutFn(() => setShowHistory(false), 100);

  const closeHistory = useCallback(() => {
    if (isReadyToCloseHistory() === false) cancelCloseHistory();
    else resetCloseHistory();
  }, [cancelCloseHistory, isReadyToCloseHistory, resetCloseHistory]);

  const dispatch = useDispatch();

  return (
    <div className="powerdash-component plot-card gap-1">
      <span className="plot-card-title p-1 mb-1">{feature.displayId}</span>
      <div
        className="plot-card-close clickable pe-2"
        onClick={() => dispatch(setSelectedPlotId(null))}
      >
        <i className="fa fa-close" />
      </div>
      <div
        className="plot-explorer"
        onMouseEnter={() => {
          cancelCloseHistory();
          setShowHistory(true);
        }}
        onMouseLeave={closeHistory}
      >
        <PlotImage
          date={trialDate}
          feature={feature}
          bottomRightContent={
            <span
              className={`font-style-italic informative history-text ${
                showHistory ? "active" : ""
              }`}
            >
              Plot history <i className="fa-solid fa-angle-right" />
            </span>
          }
        />
        {showHistory && (
          <FloatingCard hide={() => setShowHistory(false)}>
            <ImageHistory feature={feature} />
          </FloatingCard>
        )}
      </div>
      <PropertiesMasonry
        feature={feature}
        highlightedProperty={selectedTrait.technical_name}
      />
    </div>
  );
};

PlotCard.propTypes = {
  feature: PropTypes.object.isRequired,
};
