import "./HelpTooltip.css";

import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { useRef, useState } from "react";

import { ANALYTICS_EVENTS } from "../../../../constants";
import PropTypes from "prop-types";
import { useClickAway } from "react-use";
import { useTracking } from "../../../../analytics";

export const HelpTooltip = ({ helpTooltip }) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const popoverRef = useRef();
  useClickAway(popoverRef, () => setPopoverIsOpen(false));

  const { trackEvent } = useTracking();

  return (
    <>
      <i
        ref={popoverRef}
        className={`fa-solid fa-circle-question clickable help-tooltip-icon ${
          popoverIsOpen ? "active" : ""
        }`}
        id={helpTooltip.id}
        onClick={() => {
          if (!popoverIsOpen)
            trackEvent(ANALYTICS_EVENTS.HELP_TOOLTIP_OPENED, {
              id: helpTooltip.id,
            });
          setPopoverIsOpen(!popoverIsOpen);
        }}
      />
      <Popover target={helpTooltip.id} flip isOpen={popoverIsOpen}>
        <PopoverHeader>
          <i className="fa-regular fa-circle-question me-1" />
          {helpTooltip.title}
        </PopoverHeader>
        <PopoverBody>
          {helpTooltip.content}
          {helpTooltip.tips.length > 0 && (
            <div className="help-tooltip-tips p-2 mt-1">
              <span className="font-weight-bold hiphen-green-text">
                <i className="fa-regular fa-lightbulb" /> Tips for an Effective
                Use
              </span>
              <ul>
                {helpTooltip.tips.map((tip) => (
                  <li key={tip}>{tip}</li>
                ))}
              </ul>
            </div>
          )}
        </PopoverBody>
      </Popover>
    </>
  );
};

HelpTooltip.propTypes = {
  helpTooltip: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    tips: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  }).isRequired,
};
