import "./VarietyExploringRow.css";

import { Col, Row } from "reactstrap";
import { memo, useMemo, useState } from "react";
import {
  selectFilteredAggregatedFeatures,
  selectFilteredFeatures,
} from "../../../../selectors/resultMap";

import { AD_COL_CLASSES } from "../../../../constants";
import { FocusList } from "../focusList/focusList";
import { PlotRatingModal } from "../plotRating/PlotRatingModal";
import { VarietyThumbnail } from "../varietyThumbnail/varietyThumbnail";
import { hasRatePlotsRole } from "../../../../users/rolesUtil";
import no_image from "../../../../static/img/microplot_placeholder.png";
import { requestFeaturePresignedUrl } from "../../../../services/backendRequests";
import { useSelector } from "react-redux";

export const VarietyExploringRow = memo(() => {
  const [expandFocusView, setExpandFocusView] = useState(false);
  const [propertyFilter, setPropertyFilter] = useState("");
  const [highlightedProperty, setHighlightedProperty] = useState(null);

  const user = useSelector((state) => state.user);
  const [features, selectedPlotId, trial, focusedVarieties, favoriteVarieties] =
    useSelector(({ resultMap }) => [
      resultMap.features,
      resultMap.selectedPlotId,
      resultMap.trial,
      resultMap.focusedVarieties,
      resultMap.favoriteVarieties,
    ]);
  const filteredFeatures = useSelector(selectFilteredFeatures);

  const selectedFeature = useMemo(() => {
    return selectedPlotId
      ? features.find(({ id }) => id === selectedPlotId)
      : null;
  }, [selectedPlotId, features]);

  const aggregatedFeatures = useSelector(selectFilteredAggregatedFeatures);
  const groups = useMemo(
    () => aggregatedFeatures.map(({ group }) => group),
    [aggregatedFeatures]
  );

  // Images
  const [storedImages, setStoredImages] = useState({});

  const getImage = async (feature, layer) => {
    const { date, id } = feature;
    const key = `${id} ${date} ${layer}`;
    if (storedImages[key]) return storedImages[key];
    try {
      const image = await requestFeaturePresignedUrl(
        trial,
        feature,
        date,
        user
      );
      setStoredImages({ ...storedImages, [key]: image });
      return image;
    } catch {
      return { url: no_image, blank: true };
    }
  };

  const currentContract = useMemo(() => {
    return trial != null
      ? user.contracts.find(({ id }) => id === trial?.contract_id)
      : null;
  }, [user.contracts, trial]);

  const plotRatingConfig = useMemo(
    () => currentContract?.plot_rating_config ?? [],
    [currentContract]
  );

  const userCanRatePlots =
    plotRatingConfig.length > 0 && hasRatePlotsRole(currentContract.roles);

  // Plot rating
  const [plotRatingModalIsOpen, setPlotRatingModalOpen] = useState(false);

  const focusedPlots = useMemo(
    () =>
      filteredFeatures
        .filter(({ group }) => focusedVarieties.includes(group))
        // Sorting elements based on their variety so they can be iterated through in the same order they are displayed
        .sort(
          (a, b) =>
            focusedVarieties.indexOf(a.group) -
            focusedVarieties.indexOf(b.group)
        ),
    [filteredFeatures, focusedVarieties]
  );

  const FocusListComponent = (
    <FocusList
      groups={groups}
      expandFocusView={expandFocusView}
      setExpandFocusView={setExpandFocusView}
      propertyFilter={propertyFilter}
      setPropertyFilter={setPropertyFilter}
      selectedFeature={selectedFeature}
      openPlotRatingModal={setPlotRatingModalOpen}
      userCanRatePlots={userCanRatePlots}
    />
  );

  return (
    <>
      {focusedVarieties.length > 0 && userCanRatePlots && (
        <PlotRatingModal
          key={focusedPlots}
          isOpen={plotRatingModalIsOpen}
          toggle={() => {
            setPlotRatingModalOpen((currentState) => !currentState);
          }}
          focusedPlots={focusedPlots}
          focusedElements={focusedVarieties}
          plotRatingConfig={plotRatingConfig}
          favoriteElements={favoriteVarieties}
        />
      )}

      {/* Layout expanded */}
      {expandFocusView ? (
        <div className="p-0 powerdash-component focus-view expanded">
          <Row className="focus-view-row g-0">
            <Col className={AD_COL_CLASSES} sm="2">
              {FocusListComponent}
            </Col>
            <Col className={AD_COL_CLASSES} sm="10">
              <div className="expanded-focus-view-content">
                <VarietyThumbnail
                  horizontal
                  index={0}
                  propertyFilter={propertyFilter}
                  getImage={getImage}
                  highlightedProperty={highlightedProperty}
                  setHighlightedProperty={setHighlightedProperty}
                />
                <VarietyThumbnail
                  horizontal
                  index={1}
                  propertyFilter={propertyFilter}
                  getImage={getImage}
                  highlightedProperty={highlightedProperty}
                  setHighlightedProperty={setHighlightedProperty}
                />
                <VarietyThumbnail
                  horizontal
                  index={2}
                  propertyFilter={propertyFilter}
                  getImage={getImage}
                  highlightedProperty={highlightedProperty}
                  setHighlightedProperty={setHighlightedProperty}
                />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        // Regular layout
        <Row className="h-100 g-0">
          <Col className={AD_COL_CLASSES} sm="3">
            {FocusListComponent}
          </Col>
          <Col className={AD_COL_CLASSES} sm="3">
            <VarietyThumbnail
              index={0}
              propertyFilter={propertyFilter}
              getImage={getImage}
              highlightedProperty={highlightedProperty}
              setHighlightedProperty={setHighlightedProperty}
            />
          </Col>
          <Col className={AD_COL_CLASSES} sm="3">
            <VarietyThumbnail
              index={1}
              propertyFilter={propertyFilter}
              getImage={getImage}
              highlightedProperty={highlightedProperty}
              setHighlightedProperty={setHighlightedProperty}
            />
          </Col>
          <Col className={AD_COL_CLASSES} sm="3">
            <VarietyThumbnail
              index={2}
              propertyFilter={propertyFilter}
              getImage={getImage}
              highlightedProperty={highlightedProperty}
              setHighlightedProperty={setHighlightedProperty}
            />
          </Col>
        </Row>
      )}
    </>
  );
});
