import { useCallback, useMemo } from "react";

import { THEME } from "../constants";
import { selectFilteredAggregatedFeatures } from "../selectors/resultMap";
import { useSelector } from "react-redux";

export const useGetGroupColor = () => {
  const focusedVarieties = useSelector(
    ({ resultMap }) => resultMap.focusedVarieties
  );

  const aggregatedFeatures = useSelector(selectFilteredAggregatedFeatures);
  const groups = useMemo(
    () => aggregatedFeatures.map(({ group }) => group),
    [aggregatedFeatures]
  );

  return useCallback(
    (gen) => {
      if (!focusedVarieties.includes(gen)) return "transparent";
      if (!groups.includes(gen)) return "lightgray"; // gray out when group is filtered

      return THEME.extendedPalette[
        focusedVarieties.indexOf(gen) % THEME.extendedPalette.length
      ];
    },
    [focusedVarieties, groups]
  );
};
