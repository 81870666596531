/* eslint-disable react-hooks/exhaustive-deps */
import {
  AD_COL_CLASSES,
  ANALYTICS_EVENTS,
  AUC_COLOR,
  DEFAULT_TRAIT_STYLE,
  MAP_MODES,
} from "../../../constants";
import { Button, Col, Row } from "reactstrap";
import { compareAsc, differenceInDays, isWithinInterval } from "date-fns";
import { setAucData, setSelectedTrait } from "../../../actions/resultMap";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useRef, useState } from "react";

import { ComponentPlaceholder } from "../../components/componentPlaceholder/componentPlaceholder";
import { ComponentTitle } from "../../components/componentTitle/componentTitle";
import { ControlSection } from "../components/ControlSection/ControlSection";
import { EvolutionChart } from "../components/charts/EvolutionChart";
import { ExportSection } from "../components/exportSection/exportSection";
import { HistogramChart } from "../components/charts/HistogramChart";
import { MultipleChart } from "../components/charts/MultipleChart";
import { OffCanvasWrapper } from "../components/advancedFiltering/OffCanvasWrapper";
import { PropertyName } from "../../../components/PropertyName/PropertyName";
import ReactSwitch from "react-switch";
import { Timeline } from "../components/Timeline/Timeline";
import { TraitSelector } from "../components/traitSelector/traitSelector";
import { TrialStats } from "../components/TrialStats/TrialStats";
import { VarietyExploringRow } from "../components/VarietyExploringRow/VarietyExploringRow";
import { WorldMap } from "../../../WorldMap";
import { formatDate } from "../../utils";
import moment from "moment";
import { selectFilteredFeatures } from "../../../selectors/resultMap";
import { useTracking } from "../../../analytics";

/*
 * Breeder report template:
 * Template: implementation of dashboard for a specific need. Defines behaviours and positioning of components
 * Breeder template is a powerful implementation to rank genotypes based on their measured traits for each date, with the
 * objective of facilitating process of taking decisions for breeders
 * exclude microplots too far away from normal distribution (ground effect, etc.)
 * analyze evolution of carefully selected genotypes, compare them overtime
 * display performance on every calculated trait
 * export results in several formats
 */
export const BreederReport = () => {
  const dispatch = useDispatch();
  const filteredDatesRef = useRef();

  const [aucModeChecked, setAucModeChecked] = useState(false);
  const [aucDatesIndexes, setAucDatesIndexes] = useState([0, 1]);

  const user = useSelector((state) => state.user);
  const [featuresByDate, trial, selectedTrait, selectedDate] = useSelector(
    ({ resultMap }) => [
      resultMap.featuresByDate,
      resultMap.trial,
      resultMap.selectedTrait,
      resultMap.trial_date,
    ]
  );

  const currentContract = useMemo(() => {
    return trial != null
      ? user.contracts.find(({ id }) => id === trial?.contract_id)
      : null;
  }, [user.contracts, trial]);

  const plotRatingConfig = useMemo(
    () => currentContract?.plot_rating_config ?? [],
    [currentContract]
  );

  // Construct traits by date
  const propertiesByDate = useMemo(() => {
    const propertiesByDate = {};
    featuresByDate?.forEach((feature) => {
      const date = feature["date"];
      if (!Object.hasOwn(propertiesByDate, date))
        propertiesByDate[date] = Object.keys(feature.properties);
    });

    return Object.entries(propertiesByDate).map(([date, traits]) => ({
      date,
      traits,
    }));
  }, [featuresByDate]);

  // Keep only dates where selected trait is available.
  const filteredDates = useMemo(() => {
    const trait = (selectedTrait.baseTrait ?? selectedTrait).technical_name;

    return propertiesByDate
      .filter(({ traits }) => traits.includes(trait))
      .map(({ date }) => date)
      .sort();
  }, [selectedTrait, propertiesByDate]);
  // Reset aucDatesIndexes on `filteredDates` change
  if (filteredDates !== filteredDatesRef.current) {
    filteredDatesRef.current = filteredDates;
    setAucDatesIndexes([0, 1]);
  }

  // AUC
  const aucData = useSelector((state) => state.resultMap.aucData);
  const { trackEvent } = useTracking();

  const onAucToggle = () => {
    setAucModeChecked(!aucModeChecked);
    trackEvent(ANALYTICS_EVENTS.AUC_MODE);
  };

  const aucDates = useMemo(
    () => [
      filteredDates[aucDatesIndexes[0]],
      filteredDates[aucDatesIndexes[1]],
    ],
    [aucDatesIndexes, filteredDates]
  );

  const computeAUC = () => {
    const aucDataCopy = JSON.parse(JSON.stringify(aucData));
    const traitLabel = `${scopeTrait.technical_name}|${moment(
      aucDates[0]
    ).format("MM-DD")} → ${moment(aucDates[1]).format("MM-DD")}`;
    if (
      !aucDataCopy.traits.find(
        ({ technical_name }) => technical_name === traitLabel
      )
    ) {
      const newTrait = {
        name: traitLabel,
        technical_name: traitLabel,
        isAddon: true,
        baseTrait: scopeTrait,
        style: DEFAULT_TRAIT_STYLE,
        traitGroup: scopeTrait.traitGroup,
        aucDates,
      };
      aucDataCopy.traits.push(newTrait);
      dispatch(setSelectedTrait(newTrait));
      setAucModeChecked(false);
    }

    const baseData = {};
    const startDate = new Date(aucDates[0]);
    const endDate = new Date(aucDates[1]);

    featuresByDate
      .toSorted((a, b) => compareAsc(new Date(a.date), new Date(b.date)))
      .forEach((feature) => {
        if (
          isWithinInterval(new Date(feature.date), {
            start: startDate,
            end: endDate,
          }) &&
          feature.properties[scopeTrait.technicalName] != null
        ) {
          if (!baseData[feature.id]) baseData[feature.id] = [];
          baseData[feature.id].push(feature);
        }
      });

    Object.entries(baseData).forEach(([id, features]) => {
      let area = 0;
      for (let i = 0; i < features.length - 1; i++)
        area +=
          0.5 *
          differenceInDays(
            new Date(features[i + 1].date),
            new Date(features[i].date)
          ) *
          (features[i].properties[scopeTrait.technicalName] +
            features[i + 1].properties[scopeTrait.technicalName]);

      if (!aucDataCopy.featureData[id]) aucDataCopy.featureData[id] = {};
      aucDataCopy.featureData[id][traitLabel] =
        area / differenceInDays(endDate, startDate);
    });

    dispatchSetAucData(aucDataCopy);
  };

  const scopeTrait = useMemo(
    () => (selectedTrait.isAddon ? selectedTrait.baseTrait : selectedTrait),
    [selectedTrait]
  );

  // FILTERS
  // Features filtered on experiments, modalities and layer
  const filteredFeatures = useSelector(selectFilteredFeatures);

  const dispatchSetAucData = (aucData) => {
    dispatch(setAucData(aucData));
  };

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
    trackEvent(ANALYTICS_EVENTS.ANALYTICS_AF_BUTTON);
  };

  const mapRef = useRef();

  /*
   * Layout
   * Jsx layout based on reactstrap row/col
   * Col must have colClasses, rows must have prop noGutters={true}
   * displays and layouts components of class "powerdash-component"
   */
  return (
    <>
      {/* First row : filters and content */}
      <Row className="row1 g-0">
        <Col className={AD_COL_CLASSES} sm="2">
          <Row className="h-100 g-0">
            <Col className={AD_COL_CLASSES} sm="12">
              <ControlSection />
              <OffCanvasWrapper
                isOffcanvasOpen={isOffcanvasOpen}
                toggleOffcanvas={toggleOffcanvas}
              />
              <TraitSelector />
              <ExportSection
                trial={trial}
                selectedDate={selectedDate}
                features={filteredFeatures}
              />
            </Col>
          </Row>
        </Col>
        <Col className={AD_COL_CLASSES} sm="10">
          {trial?.trial_dates && (
            <Row className="g-0">
              <Col className={AD_COL_CLASSES} sm="2">
                <TrialStats />
              </Col>
              <Col className={AD_COL_CLASSES} sm="8">
                <Timeline
                  dates={aucModeChecked ? filteredDates : trial.trial_dates}
                  selectedDate={selectedDate}
                  aucModeChecked={aucModeChecked}
                  aucDatesIndexes={aucDatesIndexes}
                  setAucDatesIndexes={setAucDatesIndexes}
                />
              </Col>
              <Col className={`${AD_COL_CLASSES} pe-5`} sm="2">
                <div className="powerdash-component">
                  <ComponentTitle
                    icon="fa-area-chart"
                    title="AUC Mode"
                    topRightContent={
                      filteredDates.length ? (
                        <ReactSwitch
                          onChange={onAucToggle}
                          checked={aucModeChecked}
                          disabled={!filteredDates.length}
                          uncheckedIcon={<></>}
                          className="react-switch"
                          onColor={AUC_COLOR}
                        />
                      ) : (
                        <i className="fa fa-lg fa-cog fa-spin discrete-icon" />
                      )
                    }
                  />
                  {aucModeChecked && scopeTrait && featuresByDate.length && (
                    <div className="powerdash-component transparent">
                      {filteredDates.length < 2 ? (
                        <span className="auc-btn-placeholder">
                          Not enough date to compute AUC.
                        </span>
                      ) : (
                        <Button
                          className="auc-btn"
                          disabled={filteredDates.length < 2}
                          onClick={computeAUC}
                        >
                          Compute integrative{" "}
                          <PropertyName
                            technicalName={scopeTrait.technical_name}
                          />
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          )}
          <Row className="h-100 g-0">
            <Col className={AD_COL_CLASSES} sm="6">
              <MultipleChart
                displayTabs={filteredFeatures.length > 0}
                selectedTrait={selectedTrait}
                plotRatingConfig={plotRatingConfig}
              />
            </Col>
            <Col className={AD_COL_CLASSES} sm="6">
              <HistogramChart
                trait={scopeTrait}
                exportName={`plot_distribution_${trial?.display_name}_${
                  selectedTrait.name
                }${
                  selectedTrait.isAddon ? "" : `_${formatDate(selectedDate)}`
                }`}
              />
              <EvolutionChart
                trait={selectedTrait}
                aucModeChecked={aucModeChecked}
                aucDates={aucDates}
                exportName={`evolution_${trial?.display_name}_${selectedTrait.name}`}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="row2 g-0">
        <Col className={`${AD_COL_CLASSES} position-relative`} sm="2">
          <div className="powerdash-component p-0">
            {trial ? (
              <div className="minimap">
                <WorldMap noBg mapRef={mapRef} mapMode={MAP_MODES.TRIAL} />
              </div>
            ) : (
              <ComponentPlaceholder icon="fa-th" />
            )}
          </div>
        </Col>

        <Col className={AD_COL_CLASSES} sm="10">
          <VarietyExploringRow />
        </Col>
      </Row>
    </>
  );
};
