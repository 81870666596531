import "./plotRating.css";

import {
  Badge,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  Progress,
  Row,
} from "reactstrap";

import { PlotRating } from "./PlotRating";
import PropTypes from "prop-types";
import { RadarChart } from "../charts/RadarChart";
import { Timeline } from "../Timeline/Timeline";
import { requestCreateClientRequest } from "../../../../services/backendRequests";
import { useGetGroupColor } from "../../../../hooks/useGetGroupColor";
import { useSelector } from "react-redux";
import { useState } from "react";

/*
 * PlotRatingModal is displayed in Analytics and shows the list of pre-selected microplots you can iterate through.
 * It wraps the logic around PlotRating to do "rating sessions" in which user quickly rate every plot one by one.
 */
export const PlotRatingModal = ({
  isOpen,
  toggle,
  focusedElements,
  plotRatingConfig,
  favoriteElements,
  focusedPlots,
}) => {
  const [currentPlot, setCurrentPlot] = useState(focusedPlots[0]);
  const [errorCatched, setErrorCatched] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const [trial, selectedDate] = useSelector(({ resultMap }) => [
    resultMap.trial,
    resultMap.trial_date,
  ]);
  const user = useSelector((state) => state.user);

  const getColor = useGetGroupColor();

  const handlePrevious = () => {
    const index = focusedPlots.indexOf(currentPlot);
    setCurrentPlot(focusedPlots.at((index - 1) % focusedPlots.length));
  };

  const handleNext = () => {
    const index = focusedPlots.indexOf(currentPlot);
    setCurrentPlot(focusedPlots.at((index + 1) % focusedPlots.length));
  };

  const ratedPlotsLength = focusedPlots.filter(({ properties }) =>
    plotRatingConfig.every(({ label }) => properties[label] != null)
  ).length;

  const progression = (ratedPlotsLength * 100) / focusedPlots.length; // In percent
  const progressEnded = ratedPlotsLength === focusedPlots.length;

  const [previousDate, setPreviousDate] = useState(null);
  if (selectedDate !== previousDate) {
    setCurrentPlot(focusedPlots[0]);
    setPreviousDate(selectedDate);
  }
  const informCampaignManager = async () => {
    try {
      await requestCreateClientRequest(
        user,
        currentPlot.site_id,
        "plot_ratings_complete"
      );
      setRequestSent(true);
    } catch {
      setRequestSent(false);
      setErrorCatched(true);
    }
  };

  return (
    <Modal className="plot-rating-modal" isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <Container fluid className="h-100">
          <Row className="h-100 row g-0">
            <Col className="h-100 vertical-stack" xs={2}>
              <div className="plot-list vertical-stack">
                {focusedElements
                  .filter((variety) =>
                    focusedPlots.some(({ group }) => group === variety)
                  )
                  .map((variety) => {
                    const varietyColor = getColor(variety);
                    return (
                      <div
                        key={variety}
                        className="variety-group vertical-stack clickable"
                        // Variety box color assigned dynamically
                        style={{
                          backgroundColor: `${varietyColor}BB`,
                          border: `2px solid ${varietyColor}`,
                        }}
                        onClick={() => {
                          // When clicking on variety select its first plot if the current plot is not part of it to avoid inconsistency
                          if (currentPlot.group !== variety)
                            setCurrentPlot(
                              focusedPlots.find(
                                ({ group }) => group === variety
                              )
                            );
                        }}
                      >
                        <span className="variety-name">
                          {favoriteElements.includes(variety) && (
                            <i className="fa fa-star" />
                          )}{" "}
                          {variety}
                        </span>
                        {focusedPlots
                          .filter(({ group }) => group === variety)
                          .map((plot) => (
                            <div
                              key={plot.id}
                              className={`variety-group-plot clickable ${
                                currentPlot.id === plot.id ? "current" : ""
                              }`}
                              id={plot.id}
                              onClick={(event) => {
                                event.stopPropagation();
                                setCurrentPlot(plot);
                              }}
                            >
                              <span>{plot.displayId}</span>
                              <div className="variety-group-plot-ratings">
                                {plotRatingConfig.map(({ label }) => (
                                  <Badge
                                    key={label}
                                    className="hiphen-badge small"
                                  >
                                    {plot.properties[label]}
                                  </Badge>
                                ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    );
                  })}
              </div>
              <Button className="back-button" onClick={toggle}>
                <i className="fa fa-arrow-left" /> Back to analytics
              </Button>
            </Col>
            <Col className="vertical-stack" xs={10}>
              <Timeline
                dates={trial.trial_dates}
                selectedDate={selectedDate}
                aucModeChecked={false}
              />
              <PlotRating
                currentPlot={currentPlot}
                trial={trial}
                user={user}
                date={selectedDate}
                varietyColor={getColor(currentPlot?.group)}
                plotRatingConfig={plotRatingConfig}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                radarChart={
                  <RadarChart
                    data={currentPlot}
                    exportName={`Radar chart ${trial.name} ${currentPlot?.displayId} ${selectedDate}`}
                  />
                }
                renderProgression={
                  <div className="plot-rating-progression">
                    <span className="text-danger pe-2">
                      {errorCatched && "Request already sent"}
                    </span>
                    <span className="text-success pe-2">
                      {requestSent && "Request have been sent"}
                    </span>
                    {ratedPlotsLength}/{focusedPlots.length}{" "}
                    <small>{progression.toFixed(0)}%</small>
                    <Progress
                      value={progression}
                      color="success"
                      animated={!progressEnded}
                    >
                      {progressEnded && (
                        <span>
                          <i className="fa fa-check" /> Selected plots
                        </span>
                      )}
                    </Progress>
                    {progressEnded && (
                      <Button
                        className="hiphen-green-button"
                        onClick={informCampaignManager}
                        disabled={errorCatched}
                      >
                        Notify Hiphen
                      </Button>
                    )}
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

PlotRatingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  focusedPlots: PropTypes.array.isRequired,
  focusedElements: PropTypes.array.isRequired,
  plotRatingConfig: PropTypes.array.isRequired,
  favoriteElements: PropTypes.array.isRequired,
};
