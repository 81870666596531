import "./Navbar.css";

import { Badge, Button } from "reactstrap";
import {
  hasAnalyticsRole,
  hasMonitoringRole,
  hasPcaRole,
} from "../../users/rolesUtil";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { FloatingCard } from "../floatingCard/FloatingCard";
import { Hr } from "../Hr";
import PropTypes from "prop-types";
import { fullResetResultMap } from "../../actions/resultMap";
import { hasBackofficeAccess } from "../../services/roles";
import { logout } from "../../actions/user";
import moment from "moment";
import { useState } from "react";
import { useTimeoutFn } from "react-use";

const HiphenSuite = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="hiphen-suite">
      <div
        className="hiphen-suite-icon ms-2 me-2 clickable"
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <FloatingCard hide={() => setIsOpen(false)}>
          <a
            className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
            href="/"
          >
            <i className="hiphen-suite-link-icon cloverfield p-1" />
            Cloverfield
          </a>
          <a
            className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
            href="https://hiphen-plant.com/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="hiphen-suite-link-icon website p-1" />
            Hiphen <i className="fa fa-external-link discrete-icon" />
          </a>
          <span className="font-weight-bold">Our products</span>
          <a
            className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
            href="https://hiphen-phenoscale.com/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="hiphen-suite-link-icon phenoscale p-1" />
            PhenoScale <i className="fa fa-external-link discrete-icon" />
          </a>
          <a
            className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
            href="https://hiphen-phenomobile.com/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="hiphen-suite-link-icon phenomobile p-1" />
            PhenoMobile <i className="fa fa-external-link discrete-icon" />
          </a>
          <a
            className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
            href="https://hiphen-literal.com/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="hiphen-suite-link-icon literal p-1" />
            Literal <i className="fa fa-external-link discrete-icon" />
          </a>
          <a
            className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
            href="https://hiphen-phenolite.com/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="hiphen-suite-link-icon phenolite p-1" />
            PhenoLite <i className="fa fa-external-link discrete-icon" />
          </a>
          <a
            className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
            href="https://hiphen-phenostation.com/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="hiphen-suite-link-icon phenostation p-1" />
            PhenoStation <i className="fa fa-external-link discrete-icon" />
          </a>
          <a
            className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
            href="https://hiphen-phenoresearch.com/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="hiphen-suite-link-icon phenoresearch p-1" />
            PhenoResearch <i className="fa fa-external-link discrete-icon" />
          </a>
          {hasBackofficeAccess(user.self) && (
            <>
              <span className="font-weight-bold">Backoffice</span>
              <a
                className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
                href="https://hcc.hiphen-plant.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="hiphen-suite-link-icon hcc p-1" />
                HCC <i className="fa fa-external-link discrete-icon" />
              </a>
              <a
                className="hiphen-suite-link clickable d-flex gap-2 align-items-center"
                href="https://phenostation.hiphen-plant.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="hiphen-suite-link-icon hcc p-1" />
                HCC Phenostation{" "}
                <i className="fa fa-external-link discrete-icon" />
              </a>
            </>
          )}
        </FloatingCard>
      )}
    </div>
  );
};

const CloverfieldLogo = () => {
  const history = useHistory();
  return (
    <i
      // Christmas version in december
      className={`navbar-logo clickable ${
        moment().month() === 11 ? "xmas" : ""
      } p-2`}
      onClick={() => history.push("/")}
    />
  );
};

const NavbarItem = ({ title, path, icon, sm, menu = [] }) => {
  const history = useHistory();
  const location = useLocation();
  const active = "/" + location.pathname.split("/")[1] === path;
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // 100ms delay before closing menu
  const [isReadyToCloseMenu, cancelCloseMenu, resetCloseMenu] = useTimeoutFn(
    () => setMenuIsOpen(false),
    100
  );

  const closeMenu = () => {
    if (isReadyToCloseMenu() === false) cancelCloseMenu();
    else resetCloseMenu();
  };

  return (
    <div
      className={`navbar-item clickable gap-1 ${active ? "active" : ""}`}
      onMouseEnter={() => {
        cancelCloseMenu();
        setMenuIsOpen(true);
      }}
      onMouseLeave={closeMenu}
      {...(sm && {
        "data-tooltip-id": "tooltip",
        "data-tooltip-place": "bottom",
        "data-tooltip-content": title,
      })}
    >
      <span
        className="d-flex gap-2 p-2 navbar-item-link"
        onClick={() => history.push(path)}
      >
        {icon} {!sm && <span className="navbar-item-title">{title}</span>}
      </span>
      {menuIsOpen && menu.length > 1 && (
        <FloatingCard>
          {menu.map((menuItem) => (
            <span
              key={menuItem.title}
              onClick={() => {
                setMenuIsOpen(false);
                history.push(`${path}${menuItem.path}`);
              }}
              className={`navbar-item-link clickable navbar-item-menu-item d-flex align-items-center gap-2 p-2 ${
                "/" + location.pathname.split("/")[2] === menuItem.path
                  ? "active"
                  : ""
              }`}
            >
              {menuItem.icon}
              <span>{menuItem.title}</span>
            </span>
          ))}
        </FloatingCard>
      )}
    </div>
  );
};

const Avatar = ({ user }) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(logout());
    dispatch(fullResetResultMap());
  };

  return (
    <div className="user-menu">
      <div className="avatar clickable" onClick={() => setIsOpen(true)} />
      {isOpen && (
        <FloatingCard hide={() => setIsOpen(false)}>
          <div className="d-flex gap-2 align-items-center">
            <div className="avatar" />
            <div className="d-flex flex-column">
              <span className="fw-bold">{user.self.fullname}</span>
              <span>{user.self?.company?.name}</span>
            </div>
          </div>
          <div className="d-flex gap-2">
            {user.self.role && (
              <Badge className="hiphen-badge flex-grow-1 d-flex align-items-center justify-content-center">
                {user.self.role}
              </Badge>
            )}
            <Button className="logout-button" size="sm" onClick={handleLogout}>
              <i className="fa fa-sign-out" /> Log out
            </Button>
          </div>
          <Hr />
          <div className="d-flex gap-2">
            <Button
              className="hiphen-green-button flex-grow-1"
              size="sm"
              onClick={() => history.push("/contact")}
            >
              <i className="fa fa-envelope" /> Contact us
            </Button>
            <Button
              className="hiphen-green-button flex-grow-1"
              size="sm"
              onClick={() => history.push("/legals")}
            >
              <i className="fa fa-gavel" /> Legals
            </Button>
          </div>
        </FloatingCard>
      )}
    </div>
  );
};

export const Navbar = () => {
  const user = useSelector(({ user }) => user);
  const userCanViewMonitoring = user.contracts.some((contract) =>
    hasMonitoringRole(contract.roles)
  );
  const userCanViewAnalytics = user.contracts.some((contract) =>
    hasAnalyticsRole(contract.roles)
  );
  const userCanViewPca = user.contracts.some((contract) =>
    hasPcaRole(contract.roles)
  );

  const analyticsMenu = [];
  if (userCanViewAnalytics)
    analyticsMenu.push({
      icon: <i className="fa-solid fa-dna" />,
      title: "Main data exploration",
      path: "/breeding",
    });

  if (userCanViewPca)
    analyticsMenu.push({
      icon: <i className="fa-solid fa-circle-nodes" />,
      title: "Diversity mapping (PCA)",
      path: "/pca",
    });

  return (
    <nav className="navbar ps-4 pe-4 gap-2">
      <HiphenSuite user={user} />
      <CloverfieldLogo />
      <NavbarItem
        title="World Map"
        path="/map"
        icon={<i className="fa-solid fa-earth-europe" />}
      />
      {userCanViewAnalytics && (
        <NavbarItem
          title="Analytics"
          path="/analytics"
          icon={<i className="fa-solid fa-chart-simple" />}
          menu={analyticsMenu}
        />
      )}
      <NavbarItem
        title="Campaigns"
        path="/clientdashboard"
        icon={<i className="fa-solid fa-file-signature" />}
      />
      {userCanViewMonitoring && (
        <NavbarItem
          title="Monitoring"
          path="/monitoring"
          icon={<i className="fa-solid fa-chart-pie" />}
        />
      )}
      <NavbarItem
        title="Glossary"
        path="/glossary"
        icon={<i className="fa-solid fa-book" />}
      />
      <NavbarItem
        title="Upload"
        path="/upload"
        icon={<i className="fa-solid fa-cloud-arrow-up" />}
      />
      <div className="flex-grow-1" />
      {hasBackofficeAccess(user.self) && (
        <>
          <NavbarItem
            sm
            title="Users"
            path="/users"
            icon={<i className="fa-solid fa-users" />}
          />
          <NavbarItem
            sm
            title="Upload sessions"
            path="/uploadsessions"
            icon={<i className="fa-solid fa-upload" />}
          />
          <NavbarItem
            sm
            title="Plot maps"
            path="/plot_maps"
            icon={<i className="fa-solid fa-object-group" />}
          />
          <NavbarItem
            sm
            title="Administration"
            path="/administration"
            icon={<i className="fa-solid fa-cogs" />}
          />
        </>
      )}
      <Avatar user={user} />
    </nav>
  );
};

HiphenSuite.propTypes = {
  user: PropTypes.object.isRequired,
};

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
};

NavbarItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  sm: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired
  ),
};
