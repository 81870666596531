import { Badge, Col, Container, Row } from "reactstrap";
import { HIPHEN_GREEN, MAP_MODES } from "../constants";
import Joyride, { EVENTS } from "react-joyride";
import {
  selectFilteredFeatures,
  selectSelectedTrialContractInfo,
} from "../selectors/resultMap";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useRef } from "react";

import { ControlSection } from "../powerdash/analyticsDashboard/components/ControlSection/ControlSection";
import ExperimentTools from "./ExperimentTools/ExperimentTools";
import { MapModeSelector } from "./MapModeSelector/MapModeSelector";
import { OffCanvasWrapper } from "../powerdash/analyticsDashboard/components/advancedFiltering/OffCanvasWrapper";
import { PlotView } from "./PlotView/PlotView";
import PropTypes from "prop-types";
import { TileLayerControl } from "./TileLayerControl/TileLayerControl";
import { Toolbox } from "./Toolbox/Toolbox";
import { TrialList } from "./TrialList/TrialList";
import { WORLDMAP_GUIDED_TOUR_STEPS } from "../guidedTours/guidedTours";
import { WorldMapLegend } from "./WorldMapLegend/WorldMapLegend";
import { hasExperimentsRole } from "../users/rolesUtil";
import { refreshUser } from "../actions/user";
import { requestSetCompletedTour } from "../services/backendRequests";

export const WorldMapHud = ({
  mapRef,
  selectedTileLayer,
  setSelectedTileLayer,
  isOffcanvasOpen,
  toggleOffcanvas,
}) => {
  const contractInfo = useSelector(selectSelectedTrialContractInfo);

  const [trial, selectedPlotId, mapMode] = useSelector(({ resultMap }) => [
    resultMap.trial,
    resultMap.selectedPlotId,
    resultMap.mapMode,
  ]);

  const features = useSelector(selectFilteredFeatures);

  const selectedFeature = useMemo(
    () => features.find((f) => f.id === selectedPlotId),
    [selectedPlotId, features]
  );

  // Guided tour
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const runGuidedTour = useRef(!user.self.completed_guided_tour);

  return (
    <>
      <Container fluid className="page-content worldmap-hud w-100 gap-2 pb-0">
        {trial && features.length > 0 && (
          <Joyride
            run={runGuidedTour.current}
            continuous
            showProgress
            showSkipButton
            hideCloseButton
            spotlightClicks
            callback={async ({ type }) => {
              // User completed or dismissed tour
              if (type === EVENTS.TOUR_START) {
                await requestSetCompletedTour(user);
                dispatch(refreshUser());
              }
            }}
            styles={{
              tooltip: {
                padding: "4px",
              },
              options: {
                zIndex: 9000, // Above navbar
                primaryColor: HIPHEN_GREEN,
              },
            }}
            steps={WORLDMAP_GUIDED_TOUR_STEPS.map((step) => ({
              ...step,
              disableBeacon: true,
              title: (
                <Badge className="hiphen-badge active w-100">
                  Cloverfield 3.0
                </Badge>
              ),
            }))}
          />
        )}
        <Row className="align-items-start g-0 pe-2" style={{ zIndex: 2000 }}>
          <Col sm="2">
            <ControlSection />
          </Col>
          <Col />
          <Col sm="2">
            {mapRef.current &&
              !features.length &&
              trial &&
              mapMode === MAP_MODES.TRIAL &&
              contractInfo.has_experiment &&
              hasExperimentsRole(contractInfo?.roles) && (
                <ExperimentTools map={mapRef.current} />
              )}
          </Col>
        </Row>
        {/* Row always at bottom */}
        <Row className="g-0 flex-grow-1">
          <Col className="d-flex flex-column flex-grow-1" sm="6">
            <Row className="flex-grow-1 g-0 w-75">
              {selectedFeature && <PlotView feature={selectedFeature} />}
            </Row>
            <div className="g-0 pt-3 d-flex flex-row align-items-end gap-2">
              <MapModeSelector />
              {/* <div className="flex-grow-1 powerdash-component">
                  Cool component
                </div> */}
            </div>
          </Col>
          <Col className="flex-grow-1 align-content-end pe-2" sm="4">
            {trial && <Toolbox mapRef={mapRef} />}
          </Col>
          <Col
            className="flex-grow-1 justify-content-end d-flex flex-column pb-1"
            sm="2"
          >
            {mapMode === MAP_MODES.CLUSTER && <TrialList />}
            {mapMode === MAP_MODES.TRIAL && <WorldMapLegend />}
            <TileLayerControl
              selectedTileLayer={selectedTileLayer}
              setSelectedTileLayer={setSelectedTileLayer}
            />
          </Col>
        </Row>
        <OffCanvasWrapper
          isOffcanvasOpen={isOffcanvasOpen}
          toggleOffcanvas={toggleOffcanvas}
        />
      </Container>
    </>
  );
};

WorldMapHud.propTypes = {
  mapRef: PropTypes.object.isRequired,
  selectedTileLayer: PropTypes.object.isRequired,
  setSelectedTileLayer: PropTypes.func.isRequired,
  isOffcanvasOpen: PropTypes.bool.isRequired,
  toggleOffcanvas: PropTypes.func.isRequired,
};
