import "./PrincipalComponentAnalysis.css";

import {
  AD_COL_CLASSES,
  ANALYTICS_EVENTS,
  MATRIX_FILL_METHODS,
  OTHER_NUMERICAL_PROPERTIES_GROUP,
} from "../../../../constants";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  selectFeaturesByDateWithAUCData,
  selectFilteredFeaturesByDateAndExcludedGroups,
  selectMergedTraits,
} from "../../../../selectors/resultMap";
import { useEffect, useMemo, useRef, useState } from "react";

import { BarChart } from "./components/BarChart";
import { Chart } from "../../components/charts/Chart";
import { CircleChart } from "./components/CircleChart";
import { ControlSection } from "../../components/ControlSection/ControlSection";
import { HELP_TOOLTIPS } from "../../../../helpTooltips/helpTooltips";
import { HelpTooltip } from "../../components/HelpTooltip/HelpTooltip";
import { OffCanvasWrapper } from "../../components/advancedFiltering/OffCanvasWrapper";
import { PCAScatter } from "./components/PCAScatter";
import { PCASettings } from "./components/PCASettings";
import { PCASummary } from "./PCASummary";
import { PlotHistory } from "./components/PlotHistory";
import ReactSelect from "react-select";
import { TraitsDatesMatrix } from "../../components/TraitsDatesMatrix/TraitsDatesMatrix";
import { performPCA } from "./pca";
import { selectTraitsIndexedOnTechnicalName } from "../../../../selectors/traits";
import { useSelector } from "react-redux";
import { useTracking } from "../../../../analytics";

const DEFAULT_PCA_OPTIONS = {
  threshold: 0.05,
  useFilteredData: false,
  autoCompute: true,
  fillMethod: MATRIX_FILL_METHODS.DROP,
};

export const PrincipalComponentAnalysis = () => {
  const allTraits = useSelector(selectTraitsIndexedOnTechnicalName);
  const mergedTraits = useSelector(selectMergedTraits);

  const [options, setOptions] = useState(DEFAULT_PCA_OPTIONS);
  const [selectedComponents, setSelectedComponents] = useState([0, 1]);
  const [clickedPlots, setClickedPlots] = useState([]);
  const [showLabelsOnCircle, setShowLabelsOnCircle] = useState(false);

  const featuresByDate = useSelector(
    options.useFilteredData
      ? selectFilteredFeaturesByDateAndExcludedGroups
      : selectFeaturesByDateWithAUCData
  );

  /* Compute which dates have which traits delivered */
  const traitsWithDates = useMemo(() => {
    // Avoid filling with any data if featuresByDate hasn't loaded yet
    if (featuresByDate.length === 0) return [];
    const datesForTraits = {};
    featuresByDate.forEach(({ trait_list, date }) => {
      trait_list.forEach((technicalName) => {
        if (!datesForTraits[technicalName])
          datesForTraits[technicalName] = new Set();

        datesForTraits[technicalName].add(date);
      });
    });

    const traits = [];
    Object.entries(datesForTraits).forEach(([technicalName, dates]) => {
      /* Original trait is found by technicalName without classes however
       * we want to use the full name including classes later */
      const baseName = technicalName.split("|")[0];
      const trait = allTraits[baseName];
      traits.push({
        ...trait,
        technicalName,
        dates,
      });
    });

    /* Get imported traits using mergedTraits and filtering only traits from OTHER_NUMERICAL_PROPERTIES_GROUP
     * By doing this we will push the imported traits to the end of the traitsWithDates with `null` as the only available date */
    const importedTraits = mergedTraits.filter(
      ({ traitGroup }) => traitGroup.name === OTHER_NUMERICAL_PROPERTIES_GROUP
    );
    // Push imported traits deduced from features at current selected date
    importedTraits.forEach((trait) => {
      traits.push({
        ...trait,
        dates: new Set([null]),
      });
    });

    return traits;
  }, [featuresByDate, mergedTraits, allTraits]);

  const selectedDatesForTraitsDefaultValue = traitsWithDates.reduce(
    (traitsDates, trait) => ({
      ...traitsDates,
      /* Deselect heterogeneities, custom data and legacy traits by default */
      [trait.technicalName]:
        trait.technicalName.includes("heterogeneity") ||
        ["Legacy", OTHER_NUMERICAL_PROPERTIES_GROUP].includes(
          trait.traitGroup.name
        )
          ? new Set()
          : trait.dates,
    }),
    {}
  );

  const [selectedDatesForTraits, setSelectedDatesForTraits] = useState(
    selectedDatesForTraitsDefaultValue
  );

  /* Reset selectedDatesForTraits when featuresByDate changes because if some traits doesn't exist anymore
   * by selecting useFilteredData for example, it might break pca computation */
  const prevFeaturesbydate = useRef(featuresByDate);
  if (featuresByDate !== prevFeaturesbydate.current) {
    prevFeaturesbydate.current = featuresByDate;
    setSelectedDatesForTraits(selectedDatesForTraitsDefaultValue);
  }

  const [pca, setPca] = useState(null);

  // useEffect for autocompute
  useEffect(() => {
    if (options.autoCompute)
      setPca(
        performPCA(featuresByDate, selectedDatesForTraits, options.fillMethod)
      );
  }, [
    featuresByDate,
    options.autoCompute,
    options.fillMethod,
    selectedDatesForTraits,
  ]);

  const computedPCA = useMemo(() => {
    if (pca)
      return {
        ...pca,
        components: pca.components.filter(
          ({ variance }) => variance > options.threshold
        ),
      };
    else return null;
  }, [pca, options.threshold]);

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  // If pca computes with less components than previously selected, reset
  const correctedSelectedComponents =
    computedPCA &&
    selectedComponents.some((index) => index >= computedPCA.components.length)
      ? [0, 1]
      : selectedComponents;

  const { trackEvent } = useTracking();
  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
    trackEvent(ANALYTICS_EVENTS.ANALYTICS_AF_BUTTON);
  };

  const deselectPlot = (plot) => () => {
    setClickedPlots(clickedPlots.filter((p) => p.id !== plot.id));
  };

  const componentsOptions = computedPCA
    ? computedPCA.components.map(({ name }, index) => ({
        label: name,
        value: index,
      }))
    : [];

  // Synchronize history scrolls
  useEffect(() => {
    const divs = document.querySelectorAll(".image-history");
    const syncScrolls = (div) => {
      divs.forEach((d) => {
        d.scrollTop = div.scrollTop;
        d.scrollLeft = div.scrollLeft;
      });
    };
    const eventListeners = [];
    divs.forEach((div) => {
      const eventListener = () => syncScrolls(div);
      eventListeners.push(eventListener);
      div.addEventListener("scroll", eventListener);
    });
    return () => {
      divs.forEach((div, index) =>
        div.removeEventListener("scroll", eventListeners[index])
      );
    };
    // Dependant on clickedPlots to resync scrolls after adding or removing plots
  }, [clickedPlots]);

  return (
    <>
      <Row className="g-0 row1">
        <Col className={AD_COL_CLASSES} sm="2">
          <ControlSection />
          <OffCanvasWrapper
            isOffcanvasOpen={isOffcanvasOpen}
            toggleOffcanvas={toggleOffcanvas}
          />
          <TraitsDatesMatrix
            key={traitsWithDates}
            traitsWithDates={traitsWithDates}
            selectedDatesForTraits={selectedDatesForTraits}
            setSelectedDatesForTraits={setSelectedDatesForTraits}
          />
        </Col>
        <Col className={AD_COL_CLASSES} sm="10">
          {computedPCA && (
            <Row className="g-0">
              <Col className={AD_COL_CLASSES} sm="6">
                <PCASummary computedPCA={computedPCA} />
              </Col>
              <Col className={`${AD_COL_CLASSES} pe-5`} sm="6">
                <div className="powerdash-component gap-1">
                  <span className="font-weight-bold discrete-text">
                    Displayed components
                  </span>
                  <div className="align-items-center d-flex flex-row gap-2">
                    <ReactSelect
                      className="flex-grow-1"
                      value={componentsOptions[selectedComponents[0]]}
                      options={componentsOptions}
                      onChange={(option) =>
                        setSelectedComponents([
                          option.value,
                          selectedComponents[1],
                        ])
                      }
                    />
                    <ReactSelect
                      className="flex-grow-1"
                      value={componentsOptions[selectedComponents[1]]}
                      options={componentsOptions}
                      onChange={(option) =>
                        setSelectedComponents([
                          selectedComponents[0],
                          option.value,
                        ])
                      }
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row className="g-0 row1">
            <Col className={AD_COL_CLASSES} sm="6">
              <Chart
                icon="fa-solid fa-arrows"
                title="Plots projection"
                missingData={!computedPCA || computedPCA.components.length < 2}
                topRightContent={
                  <HelpTooltip helpTooltip={HELP_TOOLTIPS.PCA_SCATTER} />
                }
              >
                <PCAScatter
                  selectedComponents={correctedSelectedComponents}
                  computedPCA={computedPCA}
                  clickedPlots={clickedPlots}
                  setClickedPlots={setClickedPlots}
                />
              </Chart>
            </Col>
            <Col className={AD_COL_CLASSES} sm="6">
              <Chart
                icon="fa-solid fa-compass"
                title="Variables Correlation"
                topRightContent={
                  <HelpTooltip helpTooltip={HELP_TOOLTIPS.PCA_CIRCLE} />
                }
                missingData={!computedPCA || computedPCA.components.length < 2}
              >
                <CircleChart
                  computedPCA={computedPCA}
                  selectedComponents={correctedSelectedComponents}
                  showLabels={showLabelsOnCircle}
                />
                <FormGroup switch className="d-flex align-items-baseline gap-2">
                  <Input
                    type="switch"
                    checked={showLabelsOnCircle}
                    onChange={() => setShowLabelsOnCircle(!showLabelsOnCircle)}
                  />
                  <Label check>Show labels</Label>
                </FormGroup>
              </Chart>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-0 row2">
        <Col className={AD_COL_CLASSES} sm="2">
          <PCASettings
            options={options}
            setOptions={setOptions}
            handleCompute={() =>
              setPca(
                performPCA(
                  featuresByDate,
                  selectedDatesForTraits,
                  options.fillMethod
                )
              )
            }
            disableCompute={featuresByDate.length === 0}
          />
        </Col>
        <Col className={AD_COL_CLASSES} sm="10">
          <Row className="h-100 g-0">
            <Col className={AD_COL_CLASSES} sm="3">
              <Chart
                icon="fa-solid fa-chart-bar"
                title="Components explained variance"
                missingData={!computedPCA || computedPCA.components.length < 1}
              >
                <BarChart
                  computedPCA={computedPCA}
                  selectedComponents={correctedSelectedComponents}
                />
              </Chart>
            </Col>
            <Col className={AD_COL_CLASSES} sm="3">
              <PlotHistory
                plot={clickedPlots[0]}
                deselectPlot={deselectPlot(clickedPlots[0])}
              />
            </Col>
            <Col className={AD_COL_CLASSES} sm="3">
              <PlotHistory
                plot={clickedPlots[1]}
                deselectPlot={deselectPlot(clickedPlots[1])}
              />
            </Col>
            <Col className={AD_COL_CLASSES} sm="3">
              <PlotHistory
                plot={clickedPlots[2]}
                deselectPlot={deselectPlot(clickedPlots[2])}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
